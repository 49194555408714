/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThemeProvider } from "@mui/material"
import React, { useEffect, useMemo } from "react"
// import LoadingTemplate from "./components/Template/LoadingTemplate/LoadingTemplate"
import useSnack from "./hooks/useSnack"
import AppRouter from "./routes"
import BrowserIncompatible from "./components/BrowserIncompatible"
import "./bootstrap.min.css"
import "./App.scss"
// import { initialiseAppAction } from "./store/actions/auth.action"
import { useAppDispatch, useAppSelector } from "./store/hooks"
import theme from "./styles/theme"

// LEGACY
import fontawesome from "@fortawesome/fontawesome"
import regular from "@fortawesome/fontawesome-free-regular"
import solid from "@fortawesome/fontawesome-free-solid"
import { session } from "./legacy/auxStore"
import { FetchUser } from "./store/old/Authentication/Authentication.action"
import { fetchOrganizationRequest, setActiveEntity } from "./store/old/Entity/Entity.action"
import "whatwg-fetch"
import CONSTANTS from "./legacy/Constants"
import { appDowntime } from "./store/old/rootReducer"
import { errorFlash } from "./legacy/components/Flash"
import moment from "moment"
import SystemDowntime from "./legacy/components/SystemDowntime"
import { useLocation } from "react-router-dom"
import { getQueryParams } from "./legacy/utils/service"

fontawesome.library.add(regular.faUser)
fontawesome.library.add(solid.faCheck)
fontawesome.library.add(solid.faTimes)

function App (): React.ReactElement {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const systemDowntime = useAppSelector(({ app }) => app.systemDowntime)
    // const { isInitialising } = useAppSelector(({ auth }) => auth)

    const fetchSystemDowntime = async () => {
        try {
            const response = await fetch(CONSTANTS.CMS_API + "system-lockout", {
                method: "GET",
            })
            const { active, time, content } = await response.json()
            if (active) dispatch(appDowntime({ time, content }))
        } catch (e) {
            errorFlash(e)
        }
    }
    useSnack()
    useEffect(() => { void fetchSystemDowntime() }, [])

    useEffect(() => {
        // void dispatch(initialiseAppAction())
        // eslint-disable-next-line no-useless-return

        if (!session.isAuthenticated) return

        const searchParams = getQueryParams(location.search)
        const currentEntityId = searchParams?.entity_id ? +searchParams.entity_id : session.entity_id
        dispatch(setActiveEntity(currentEntityId))
        dispatch(FetchUser((user: any) => {
            user.entity_id !== -1 && dispatch(fetchOrganizationRequest(user.entity_id))
        }))
    }, [])

    const showDowntime = useMemo(() => {
        if (!systemDowntime) return false

        const now = moment()
        const dt = moment(systemDowntime.time)

        return !!now.isSameOrAfter(dt)
    }, [systemDowntime])

    const isIncompatibleBrowser = (() => {
        const ua = window.navigator.userAgent
        return /MSIE|Trident/.test(ua)
    })()

    if (isIncompatibleBrowser) {
        return <BrowserIncompatible/>
    }

    if (showDowntime) {
        return <SystemDowntime/>
    }

    // if (isInitialising) {
    //     return <LoadingTemplate />
    // }

    return <ThemeProvider theme={theme}>
        <div className="App">
            <AppRouter/>
        </div>
    </ThemeProvider>
}

export default App
