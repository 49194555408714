/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { TagFilled } from '@ant-design/icons';

// COMPONENTS
import AukDrawerRight from '../../components/AukDrawerRight';
import TextArea from '../../components/AukInput/TextArea';

import './index.scss';

const LabellerDrawer = (props) => {
    const { onClose, handlerOnClick, withHandler = true } = props;
    const [visible, setVisibility] = useState(props.visible || false);

    useEffect(() => {
        setVisibility(props.visible)
    }, [props.visible])

    return (
        <AukDrawerRight
            className="auk-drawer-labeller"
            autoFocus
            withHandler={withHandler}
            visible={visible}
            handlerIcon={<TagFilled style={{ color: '#fafafa' }} />}
            handlerTooltip="Click for forward tagging"
            handlerOnClick={() => { 
                setVisibility(true); 
                handlerOnClick();
            }}
            maskStyle={{ display: 'none', pointerEvents: 'none' }}
            style={{ position: 'absolute', pointerEvents: 'none' }}
            onClose={() => { 
                setVisibility(false); 
                onClose() 
            }}
        >
            {props.children}
        </AukDrawerRight>
    );
};

export default LabellerDrawer;

export * from './AssetIssueLabeller';
export * from './AssetProductionLabeller';
export * from './TargetProductionLabeller';
export * from './AssetForwardLabeller';

export const LabelNotesInput = (props) => {
    return (
        <TextArea
            className="label-notes-input"
            showCount
            maxLength={255}
            {...props}
        />
    );
};
