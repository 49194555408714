/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import './AukDrawerRight.scss';
import classNames from 'classnames';

const AukDrawerRight = (props) => {
    const {
        handlerTooltip = '',
        withHandler,
        onClose,
        handlerOnClick,
        handlerIcon,
        visible,
        children,
        className,
    } = props;

    const [focus, setFocus] = useState(false);

    const close = useCallback(() => {
        onClose && onClose();
    }, [visible]);

    const toggle = useCallback(() => {
        if (visible) return close();

        handlerOnClick && handlerOnClick();
    }, [visible]);

    useEffect(() => {
        focus && setTimeout(() => setFocus(false), 1000);
    }, [focus]);

    const handler = withHandler ? (
        <div 
            className={classNames({ 
                "auk-drawer__handler": true,
                "auk-drawer__handler--expanded": !!visible
            })}
            onClick={toggle}
        >
            <div 
                key={visible ? 1 : 0} 
                className={"auk-drawer__handler__icon"}
                title={handlerTooltip}
            >
                {handlerIcon ? (
                    handlerIcon
                ) : visible ? (
                    <i className="fas fa-angle-right"/>
                ) : (
                    <i className="fas fa-angle-left"/>
                )}
            </div>
        </div>
    ) : null;

    const drawerProps = { ...props };
    delete drawerProps.withHandler;
    delete drawerProps.handlerTooltip;
    delete drawerProps.handlerOnClick;
    delete drawerProps.handlerIcon;

    return (
        <>
            {handler}
            <Drawer
                width={320}
                {...drawerProps}
                className={`auk-drawer ${focus ? 'auk-drawer--focused' : ''} ${
                    className || ''
                }`}
                open={visible}
                getContainer={props.getContainer}
                closable={false}
            >
                {visible ? <DrawerClose onClick={close} /> : null}
                {visible ? children : null}
            </Drawer>
        </>
    );
};

const DrawerClose = ({ onClick }) => {
    return (
        <div className="auk-drawer__close" onClick={onClick}>
            <CloseOutlined />
        </div>
    );
};

export default AukDrawerRight;
