import React, { useEffect, useMemo, useState } from "react"
import { useAppSelector } from "../../../../store/hooks"
import { skusSelector } from "../../../../store/old/Sku/Sku.selector"
import { values } from "lodash"
import WithBarcode from "../../../components/WithBarcode"
import LabelWrapper from "../../../components/LabelWrapper"
import { Input, Select } from "antd"
import { regexMatch } from "../../../utils/helpers"

const ProductionFieldsInputs = (props: any) => {
    const { data, onChange, fields } = props

    const skus = useAppSelector(skusSelector)

    const [state, setState] = useState(initProductionFieldsState(fields, data))
    const [reader, setReader] = useState(null)

    useEffect(() => onChange && onChange(state), [state])

    const handleChange = (field: any, value: any) => {
        setState({ ...state, [field]: value })
    }

    const handleScan = (e: any) => {
        if (!reader) return

        let value = e
        if (reader === "sku") {
            const found = values(skus).find((s) => s.code === e)
            value = found ? found.sku_id : null
        }

        handleChange(reader, value)
        setReader(null)
    }

    const skuOptions = useMemo(
        () =>
            values(skus).map((s) => ({
                value: s.sku_id,
                label: `[${s.code}] ${s.name}`,
            })),
        [skus]
    )

    return (
        <>
            {fields &&
        fields.map(({ field }: any, i: number) => {
            const component =
            field === "sku" ? 
                <ProductionFieldItemSelect
                    field={field}
                    options={skuOptions}
                    value={skus[state[field]] ? state[field] : ""} // check if sku exists
                    handleChange={handleChange}
                />
                : 
                <ProductionFieldItemInput
                    field={field}
                    value={state[field]}
                    handleChange={handleChange}
                />
            
            return (
                <WithBarcode
                    key={i}
                    handleClick={() => setReader(field)}
                    handleScan={handleScan}
                >
                    {component}
                </WithBarcode>
            )
        })}
        </>
    )
}

export default ProductionFieldsInputs

const initProductionFieldsState = (fields: any, currentLabel: any) => {
    return fields.reduce((acc: any, curr: any) => {
        let value = ""

        if (currentLabel) {
            const fieldValue = currentLabel.getValue(curr.field)
            if (curr.field === "sku") {
                value = fieldValue ? fieldValue.sku_id : ""
            } else {
                value = fieldValue || ""
            }
        }

        return {
            ...acc,
            [curr.field]: value,
        }
    }, {})
}

const ProductionFieldItemSelect = (props: any) => {
    const { field, options, handleChange, value } = props
    return (
        <LabelWrapper label={field} className="production-label-field-input">
            <Select
                className="w-100"
                popupClassName="labeller-sku-select-dropdown"
                onChange={(e: any) => handleChange(field, e)}
                value={value}
                showSearch
                filterOption={(inp: any, opt: any) => regexMatch(opt.title, inp)}
                showArrow={false}
            >
                {options.map((o: any) => 
                    <Select.Option key={o.value} value={o.value} title={o.label}>
                        {o.label}
                    </Select.Option>
                )}
            </Select>
        </LabelWrapper>
    )
}

const ProductionFieldItemInput = (props: any) => {
    const { field, handleChange, value } = props
    return (
        <LabelWrapper label={field} className="production-label-field-input">
            <Input
                onChange={(e: any) => handleChange(field, e.target.value)}
                value={value}
            />
        </LabelWrapper>
    )
}
