/* eslint-disable react/prop-types */
import React from 'react';
import { find, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { Table, Menu } from 'antd';

// COMPONENTS
import AukButton from '../../components/AukButton';
import ConfirmationModal from '../../components/ConfirmationModal';
import FilterSearch from '../../components/FilterSearch';
import AukTooltip from '../../components/AukTooltip';
import SKUFormModal from './SKUFormModal';
import { saveCsv } from '../../utils/dataExports';
import translate from '../../utils/translate';
import { Permission, RolePermission } from '../../components/Permission';
import { flash } from '../../components/Flash';

// SELECTORS
import { arraySkus } from '../../../store/old/Sku/Sku.selector';

import './SKUManagement.scss';
import { SPAWrapper } from '../../components/SPAWrapper';
import CsvFileUpload from '../../components/CSVFileUpload';

const CSV = {
    UPLOAD: 'upload',
    TEMPLATE: 'template',
};

const FILTER = {
    ALL: 'all',
    CATEGORY: 'category',
};

const isDuplicateSKUCode = (skuArray, code) =>
    find(skuArray, (v) => v.code === code);

const defaultConfirmationState = {
    show: false,
    message: '',
    action: '',
    onConfirmation: () => {},
};

const DuplicateWarning = (code) => {
    return (
        <>
            <p>
                <strong>{code}</strong> already exist(s).
            </p>
            <p>
        Non-unique SKU codes may lead to inaccuracies in SKU labeling. Continue
        anyway?
            </p>
        </>
    );
};

const getComponentStateObject = (skus) => {
    const categories = uniqBy(skus, (v) => v.category).map((v) => v.category);

    const categoriesFilter = uniqBy(skus, (v) => v.category).map((v) => {
        return {
            text: v.category,
            value: v.category,
        };
    });

    const skuName = uniqBy(skus, (v) => v.name).map((v) => {
        return {
            text: v.name,
            value: v.name,
        };
    });

    const skuCode = uniqBy(skus, (v) => v.code).map((v) => {
        return {
            text: v.code,
            value: v.code,
        };
    });

    const uom = uniqBy(skus, (v) => v.uom).map((v) => {
        return {
            text: v.uom,
            value: v.uom,
        };
    });

    return {
        data: skus,
        categoriesFilter,
        categories,
        skuName,
        skuCode,
        uom,
    };
};

export class SKUManagement extends React.Component {
    constructor(props) {
        super(props);

        this.timeOutSearch;

        this.state = {
            activeFilter: FILTER.ALL,
            search: '',
            showCsv: false,
            data: [],
            isOpenedDeleteConfirmation: false,
            categories: [],
            categoriesFilter: [],
            skuName: [],
            skuCode: [],
            uom: [],
            confirmation: { ...defaultConfirmationState },
            showFormModal: false,
            editing: null,
        };

        this.editHandler = this.editHandler.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.onSubmitNew = this.onSubmitNew.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.onSubmitCsv = this.onSubmitCsv.bind(this);
        this.toggleCsv = this.toggleCsv.bind(this);
        this.delete = this.delete.bind(this);
        this.openDeleteConfirmation = this.openDeleteConfirmation.bind(this);
        this.cancelConfirmation = this.cancelConfirmation.bind(this);
        this.handleSelectFilter = this.handleSelectFilter.bind(this);

        this.openFormModal = this.openFormModal.bind(this);
        this.hideFormModal = this.hideFormModal.bind(this);
    }

    componentDidMount() {
        this.setState(getComponentStateObject(this.props.skus));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.skus !== this.props.skus) {
            return this.setState(getComponentStateObject(this.props.skus));
        }
    }

    filterValue = () => {
        const { ALL, CATEGORY } = FILTER;

        const { search, activeFilter } = this.state;

        const { skus } = this.props;

        if (search.length === 0) return skus;

        const filteredSkus = skus.filter((sku) => {
            const category = sku.category
                ? sku.category.toLowerCase().indexOf(search.toLowerCase()) !== -1
                : false;
            const name = sku.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            const code = sku.code.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            const uom = sku.uom.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            const description = sku.description
                ? sku.description.toLowerCase().indexOf(search.toLowerCase()) !== -1
                : false;

            switch (activeFilter) {
            case ALL:
                return category || name || code || uom || description;
            case CATEGORY:
                return category;
            default:
                return true;
            }
        });

        return filteredSkus;
    };

    handleChangeSearch(e) {
        this.setState({ search: e.target.value }, () => {
            const { search, activeFilter } = this.state;

            if (search.trim().length === 0) {
                clearTimeout(this.timeOutSearch);
                return this.handleSelectFilter(activeFilter);
            }

            if (this.timeOutSearch) {
                clearTimeout(this.timeOutSearch);
            }

            this.timeOutSearch = setTimeout(() => {
                const filteredSkus = this.filterValue();

                this.setState(getComponentStateObject(filteredSkus));
            }, 1000);
        });
    }

    openFormModal(editing = null) {
        this.setState({
            showFormModal: true,
            editing,
        });
    }

    hideFormModal() {
        this.setState({
            showFormModal: false,
            editing: null,
        });
    }

    template() {
        saveCsv(
            'Sku Name (required),Unit of Measurement (required),Code (required),Sku Category (optional),Sku Description (optional)',
            'sku_template.csv'
        );
    }

    onSubmitNew(submission) {
        const { category, name, code, uom, description } = submission;
        const { onCreateSku, skus } = this.props;

        const createHandler = (cb) =>
            onCreateSku({ category, name, code, uom, description }, () => {
                flash({ message: `SKU created`, status: 'success' });

                cb && cb();
            });

        const duplicate = isDuplicateSKUCode(skus, code);

        if (duplicate) {
            this.setState({
                confirmation: {
                    show: true,
                    action: 'Create SKU',
                    message: DuplicateWarning(code),
                    onConfirmation: () =>
                        createHandler(() => this.cancelConfirmation(this.hideFormModal)),
                },
            });
            return;
        }

        createHandler(this.hideFormModal);
    }

    openDeleteConfirmation() {
        const message = (
            <div>
                <p>Warning! This action is not reversible.</p>
                <p>
          Deleting this SKU will also lead to the removal of all its associated
          data.
                </p>
                <p>Would you like to continue?</p>
            </div>
        );

        this.setState({
            confirmation: {
                show: true,
                message,
                action: 'Delete SKU',
                onConfirmation: this.delete,
            },
        });
    }

    cancelConfirmation(cb) {
        this.setState(
            {
                confirmation: { ...defaultConfirmationState },
            },
            () => cb && cb()
        );
    }

    delete() {
        const { editing } = this.state;
        const { onDeleteSku } = this.props;

        onDeleteSku(editing, () => {
            flash({ message: `SKU deleted`, status: 'success' });
            this.cancelConfirmation(() => this.setState({ editing: null }));
        });
    }

    onSubmitEdit(submission) {
        const {
            editing: { sku_id },
        } = this.state;
        const { onEditSku, skus } = this.props;

        const editHandler = (cb) =>
            onEditSku({ sku_id, ...submission }, () => {
                flash({ message: `SKU updated`, status: 'success' });
                cb && cb();
            });

        const duplicate = isDuplicateSKUCode(
            skus.filter((sku) => sku.sku_id !== sku_id),
            submission.code
        );

        if (duplicate) {
            this.setState({
                confirmation: {
                    show: true,
                    action: 'Update SKU',
                    message: DuplicateWarning(submission.code),
                    onConfirmation: () =>
                        editHandler(() => this.cancelConfirmation(this.hideFormModal)),
                },
            });
            return;
        }

        editHandler(this.hideFormModal);
    }

    toggleCsv() {
        this.setState((prev) => {
            return { showCsv: !prev.showCsv };
        });
    }

    bulkCreateSkus = (data, cb) =>
        this.props.onBulkCreateSku(data, () => {
            flash({ message: `SKUs created`, status: 'success' });
            cb && cb();
        });

    onSubmitCsv(arr) {
        const { skus } = this.props;

        if (Array.isArray(arr) && arr.length === 0) {
            return flash({
                message: 'Upload SKU Error',
                details: 'File can not be empty',
                status: 'error',
            });
        }

        const rows = arr.map(([name, uom, code, category, description]) => ({ name, uom, code, category, description }));
        const codes = new Set(skus.map(({ code }) => code));
        const duplicates = rows.filter(({ code }) => codes.has(code));

        if (duplicates.length) {
            const str = duplicates.map(({ code }) => code).join(', ');
            this.setState({
                confirmation: {
                    show: true,
                    action: 'Create SKUs',
                    message: DuplicateWarning(str),
                    onConfirmation: () =>
                        this.bulkCreateSkus(rows, () => this.cancelConfirmation(this.toggleCsv)),
                },
            });
            return;
        }

        this.bulkCreateSkus(rows, this.toggleCsv);
    }

    handleSelectFilter(cat) {
        if (cat === FILTER.CATEGORY || cat === FILTER.ALL) {
            this.setState({ activeFilter: cat }, () => {
                const filteredSkus = this.filterValue();

                this.setState(getComponentStateObject(filteredSkus));
            });
        }
    }

    get header() {
        const filterOptions = [
            { value: FILTER.ALL, label: translate(FILTER.ALL) },
            { value: FILTER.CATEGORY, label: translate(FILTER.CATEGORY) },
        ];

        const FilterGroup = (
            <FilterSearch
                selectProps={{
                    style: { width: 120, textAlign: 'left' },
                    value: this.state.activeFilter,
                    options: filterOptions,
                    onChange: this.handleSelectFilter,
                }}
                inputProps={{
                    style: { flexGrow: 1 },
                    onChange: this.handleChangeSearch,
                    value: this.state.search,
                }}
            />
        );

        const csvOptions = [
            { key: CSV.UPLOAD, label: 'Upload skus (.csv)' },
            { key: CSV.TEMPLATE, label: 'Download template (.csv)' },
        ];

        const handleClickMenu = ({ key }) => {
            switch (key) {
            case CSV.UPLOAD:
                this.toggleCsv();
                return;
            case CSV.TEMPLATE:
                this.template();
                return;
            default:
                return;
            }
        };

        const CreateButton = (
            <AukTooltip.Help title={"New"}>
                <AukButton.Outlined
                    className="auk-button--round"
                    onClick={() => this.openFormModal()}
                >
                    <i className="fas fa-plus" />
                </AukButton.Outlined>
            </AukTooltip.Help>
        );

        const BulkCreateButton = (
            <AukTooltip.Help title="Bulk Create" placement="top">
                <AukButton.Dropdown
                    className="auk-button--round p-0 m-0 ml-2"
                    icon={<i className="fas fa-upload" />}
                    overlay={
                        <Menu onClick={handleClickMenu}>
                            {csvOptions.map((o) => (
                                <Menu.Item key={o.key}>{o.label}</Menu.Item>
                            ))}
                        </Menu>
                    }
                />
            </AukTooltip.Help>
        );

        return (
            <div className="d-flex justify-content-between">
                <div className="d-flex" style={{ width: '30%' }}>
                    {FilterGroup}
                </div>
                <Permission forResource resource="skus" canDo="edit">
                    <RolePermission accessLevel="editor">
                        <div
                            className="d-flex justify-content-end"
                            style={{ width: '30%' }}
                        >
                            {CreateButton}
                            {BulkCreateButton}
                        </div>
                    </RolePermission>
                </Permission>
            </div>
        );
    }

    editHandler(cellInfo) {
        return (
            <div className="d-flex justify-content-center">
                <Permission forResource resource="skus" canDo="edit">
                    <span
                        className="p-1"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openFormModal(cellInfo)}
                    >
                        <i className="far fa-edit" />
                    </span>
                </Permission>
                <Permission forResource resource="skus" canDo="full">
                    <span
                        className="p-1"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            this.setState({ editing: cellInfo }, this.openDeleteConfirmation)
                        }
                    >
                        <i className="far fa-trash-alt" />
                    </span>
                </Permission>
            </div>
        );
    }

    get columns() {
        const { categoriesFilter, skuName, skuCode, uom } = this.state;
        return [
            {
                title: translate('category'),
                dataIndex: 'category',
                filters: categoriesFilter,
                onFilter: (value, record) => record.category.indexOf(value) === 0,
                sorter: (a, b) => a.category.localeCompare(b.category),
                width: 100,
            },
            {
                title: translate('name'),
                dataIndex: 'name',
                filters: skuName,
                onFilter: (value, record) => record.name.indexOf(value) === 0,
                sorter: (a, b) => a.name.localeCompare(b.name),
                width: 250,
            },
            {
                title: translate('code'),
                dataIndex: 'code',
                filters: skuCode,
                onFilter: (value, record) => record.code.indexOf(value) === 0,
                sorter: (a, b) => a.code.localeCompare(b.code),
                width: 100,
            },
            {
                title: 'UOM',
                dataIndex: 'uom',
                filters: uom,
                onFilter: (value, record) => record.uom.indexOf(value) === 0,
                sorter: (a, b) => a.uom.localeCompare(b.uom),
                width: 100,
            },
            {
                title: translate('description'),
                dataIndex: 'description',
                width: 300,
            },
            {
                title: '',
                dataIndex: 'action',
                render: (text, record, index) => this.editHandler(record),
                width: 50,
            },
        ];
    }

    render() {
        const { data, confirmation, editing } = this.state;

        return (
            <SPAWrapper className="skumanagement">
                <ConfirmationModal
                    showConfirmation={confirmation.show}
                    onConfirmation={confirmation.onConfirmation}
                    toggleConfirmation={() => this.cancelConfirmation()}
                    action={confirmation.action}
                    message={confirmation.message}
                />
                <div
                    className="row justify-content-center flex-column w-100"
                    style={{
                        margin: '0 auto',
                    }}
                >
                    {this.header}
                    <Table
                        rowKey={(record) => record.sku_id}
                        size="small"
                        style={{ marginTop: 10 }}
                        columns={this.columns}
                        dataSource={data}
                        pagination={{ position: ['bottomCenter'] }}
                        key="id"
                    />
                    {this.state.showFormModal && (
                        <SKUFormModal
                            data={editing}
                            toggle={this.hideFormModal}
                            handleSubmit={editing ? this.onSubmitEdit : this.onSubmitNew}
                        />
                    )}
                    <CsvFileUpload
                        visible={this.state.showCsv}
                        onCancel={this.toggleCsv}
                        onSubmit={this.onSubmitCsv}
                        rowTransform={(s) => s.replace(/(".*?")/g, '').split(',')}
                    />
                </div>
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        skus: arraySkus(appState),
    };
};

export default connect(mapStateToProps, null)(SKUManagement);
