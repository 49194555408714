import { put, call, all, race, take, takeLatest, takeEvery } from 'redux-saga/effects';
import { flatten, uniq, mapValues, values, keys } from 'lodash';

// SERVICES
import {
    api_getAssetOEEData,
    api_getAssetChartsData,
    api_getAssetOperatorData,
    api_getSummaryTileData,
    api_getTileDataAndLabels,
} from './Data.services';
import {
    batchBlockOee, getMachineStatusQuery
} from './Data.helpers';

// ACTION
import {
    addBlocksOeeDataResource,
    addTimesSeriesDataResource,
    addSummaryTilesOeeDataResource,
    clearDataResource,
} from './Data.action';

import { setBlockBottleneck } from '../Blocks/Blocks.action';

// HELPERS
import { DataConstants as K } from './Data.constants';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { OEEFactory } from '../../../legacy/utils/oee';
import { TimeSeriesFactory } from '../../../legacy/utils/data';

// REDUX STORE
import { store } from '../..';
import { Label, parseLabelArguments } from '../../../legacy/models';
import {
    addLabelsResource,
    ResetLabelsState,
    setLabelsResource,
    setLabelsState,
} from '../Labels/Labels.action';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { toggleSummaryTileLoading } from '../UI/Dashboard/Dashboard.action';
import { errorFlash } from '../../../legacy/components/Flash';
import { assetsState } from '../Assets/Assets.selector';
import CONSTANTS from '../../../legacy/Constants';

const getDataResource = (data, idAccessor, valueAccessor) => {
    return data.reduce(
        (acc, curr) => ({ ...acc, [idAccessor(curr)]: valueAccessor(curr) }),
        {}
    );
};

// GET ASSET CHART DATA
function* handleGetAssetChartsData(action) {
    try {
        const { asset_id, query, entity_id } = action.payload;

        const [channelsData, fusionsData] = yield call(
            api_getAssetChartsData,
            entity_id,
            asset_id,
            query
        );

        const metaDataResource = getDataResource(
            channelsData,
            (d) => d.metadata_id,
            (d) => TimeSeriesFactory(d.data)
        );

        const fusionsDataResource = getDataResource(
            fusionsData,
            (d) => d.fusion_id,
            (d) => TimeSeriesFactory(d.data)
        );

        yield put(
            addTimesSeriesDataResource(metaDataResource, fusionsDataResource)
        );

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAssetChartsDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_CHARTS_DATA_REQUEST,
        handleGetAssetChartsData
    );
}

// GET ASSET OEE DATA
function* handleGetAssetOeeData(action) {
    try {
        const appState = store.getState();

        const { asset_id, query, entity_id } = action.payload;

        const res = yield call(api_getAssetOEEData, entity_id, asset_id, query);

        const { block_id } = assetsState(appState).assets[asset_id];
        yield put(addBlocksOeeDataResource({ [block_id]: OEEFactory(res) }));

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAssetOeeDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_OEE_DATA_REQUEST,
        handleGetAssetOeeData
    );
}

function* handleGetAssetOperatorData(action) {
    try {
        const appState = store.getState();
        const {
            assets: { assets },
        } = appState;

        const { asset_id, query, entity_id } = action.payload;

        const res = yield call(
            api_getAssetOperatorData,
            entity_id,
            asset_id,
            query
        );

        const labels = getMapFromArr(
            res.labels.map(
                (l) => new Label(...parseLabelArguments({ ...l, asset_id }))
            ),
            'label_id'
        );

        const { primary } = assets[asset_id];

        const data = {
            [primary.metadata_id || primary.fusion_id]: TimeSeriesFactory(res.data),
        };

        yield all([
            put(setLabelsResource(labels)),
            put(
                addTimesSeriesDataResource(
                    primary.metadata_id && data,
                    primary.fusion_id && data
                )
            ),
        ]);

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getAssetOperatorDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_OPERATOR_DATA_REQUEST,
        handleGetAssetOperatorData
    );
}

function* handleGetBlockOeeData(action) {
    try {
        yield put(ResetLabelsState());
        yield put(clearDataResource(['blocks']));

        const appState = store.getState();
        const entity = currentEntitySelector(appState)

        const { oee: blocksOee, labels: blocksLabels } = yield* batchBlockOee({ entity, ...action.payload });

        const labels = values(blocksLabels).reduce((acc, curr) => {
                                return { ...acc, ...curr };
                            }, {});

        const byBlock = mapValues(blocksLabels, (arr) =>
                    values(arr).map((l) => l.label_id)
                );

        yield all([
            put(addBlocksOeeDataResource(blocksOee)),
            put(setLabelsState(labels, byBlock))
        ])

        action.callback && action.callback({ oee: blocksOeeResource, labels: labels });
    } catch (error) {
        errorFlash(error);
    }
}

export function* getBlockOeeDataSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_BLOCK_OEE_DATA_REQUEST,
        handleGetBlockOeeData
    );
}

function* handleGetSummaryTileData(action) {
    try {
        const { ids, query, entity_id } = action.payload;

        yield put(toggleSummaryTileLoading());

        const res = yield call(
            api_getSummaryTileData,
            entity_id,
            Object.assign({}, { ids }, query)
        );

        const bn_blocks = uniq(
            flatten(res.map((v) => v.bn_blocks || []))
        );

        yield put(setBlockBottleneck(bn_blocks));

        const stOEEResource = getDataResource(
            res,
            (d) => d.summary_tile_id,
            (d) => d.overall
        );

        yield all([
            put(addSummaryTilesOeeDataResource(stOEEResource)),
            put(toggleSummaryTileLoading()),
        ]);

        action.callback && action.callback();
    } catch (error) {
        errorFlash(error);
    }
}

export function* getSummarytileDataSaga() {
    yield takeEvery(K.ACTIONS.FETCH_SUMMARY_TILE_DATA_REQUEST, handleGetSummaryTileData);
}

function* handleGetTileDataAndLabels(action) {
    try {
        const { ids, query, entity_id } = action.payload;

        const { tiles : { tiles } } = store.getState();

        const [tsSeries, ..._statusSeries] = yield all([
            call(api_getTileDataAndLabels, entity_id, Object.assign({}, { ids }, query)),
            ...ids.map(id => {
                const tile = tiles[id]
                const statusQueryParams = getMachineStatusQuery(query, tile.asset.primary.samplingRate);
                return call(api_getTileDataAndLabels, entity_id, { ids: [id], ...statusQueryParams })
            })
        ]);

        const primaryCharts = ids.reduce((acc, curr) => {
            const tile = tiles[curr];
            const key = tile.asset.primary.fusion_id ? `f-${tile.asset.primary.fusion_id}` : `m-${tile.asset.primary.metadata_id}`

            return {
                ...acc,
                [key]: {
                    samplingRate: tile.asset.primary.samplingRate,
                    mode: tile.asset.primary.mode
                }
            }
        }, {})

        const statusSeries = _statusSeries
            .reduce((acc, curr) => acc.concat(...curr.fusions, ...curr.metadata), [])
            .reduce((acc, curr) => {
                const key = curr.fusion_id ? `f-${curr.fusion_id}` : `m-${curr.metadata_id}`
                return {
                    ...acc,
                    [key]: { ...curr, ...primaryCharts[key] }
                }
            }, {})

        const labelsResource = getMapFromArr(
            tsSeries.labels
                .filter((l) => l.label_values && l.label_values.length)
                .map((l) => new Label(...parseLabelArguments({ ...l }))),
            'label_id'
        );

        const metaDataResource = tsSeries.metadata.reduce(
            (acc, curr) => {
                return {
                    ...acc,
                    [curr.metadata_id]: TimeSeriesFactory(curr.data, statusSeries[`m-${curr.metadata_id}`]),
                }},
            {}
        );

        const fusionsDataResource = tsSeries.fusions.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.fusion_id]: TimeSeriesFactory(curr.data, statusSeries[`f-${curr.fusion_id}`]),
            }),
            {}
        );

        yield all([
            put(addTimesSeriesDataResource(metaDataResource, fusionsDataResource)),
            put(addLabelsResource(labelsResource)),
        ]);

        action.callback && action.callback();
        if (action.callback) {
            action.callback(tsSeries);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* getTileDataAndLabelsSaga() {
    yield takeEvery(
        K.ACTIONS.FETCH_TILE_DATA_AND_LABELS_REQUEST,
        handleGetTileDataAndLabels
    );
}

// const getMachineStatusQuery = (query, samplingRate) => {
//     const { res_x, res_period } = getClosestResolution(samplingRate);
//     const range = Math.max(samplingRate, CONSTANTS.MAJOR_STOP_THRESHOLD);
//     return {
//         date_range: {
//             upper: query.date_range.upper,
//             lower: query.date_range.upper.clone().subtract(range, 'seconds')
//         },
//         res_x,
//         res_period
//     }
// }

// const getClosestResolution = (seconds) => {
//     const minutes = Math.floor(seconds / 60)

//     if (minutes === 0) {
//         return { res_x: 1, res_period: 'minutes' } // fetch at minimum 1 minute resolution
//     }

//     return { res_x: minutes, res_period: 'minutes' }
// }