import React, { useMemo, useState } from 'react';
import './UsersManagement.scss'
import { useSelector } from 'react-redux';
import { arrayGroups } from '../../../store/old/Group/Group.selector';
import Groups from './Groups';
import { Permission } from '../../components/Permission';
import AukTooltip from '../../components/AukTooltip';
import { Button } from 'antd';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import { arrayUsers } from '../../../store/old/User/User.selector';
import ModalGroups from './ModalGroups';
import translate from '../../utils/translate';

const GroupsTab = (props) => {
    const groups = useSelector(arrayGroups)
    const user = useSelector(userSelector)
    const users = useSelector(arrayUsers);

    const [showGroupDialog, setShowGroupDialog] = useState(false)
    const [editing, setEditing] = useState(null); // group_id

    const { isClientUser, isWhitelistedDemoUser } = user;

    const handleClickEdit = (id) => {
        setEditing(id)
        setShowGroupDialog(true);
    }

    const editingSelection = useMemo(() => groups.find(g => g.group_id === editing), [editing])

    return (
        <div className="groups-tab-content">
            <Permission forResource resource="users" canDo="edit">
                <div className="d-flex w-100 justify-content-end">
                    <AukTooltip.Help title={"Create new group"}
                    >
                        <Button
                            onClick={() => setShowGroupDialog(true)}
                            className="auk-button auk-button--round"
                        >
                            <i className="fas fa-plus" />
                        </Button>
                    </AukTooltip.Help>
                </div>
            </Permission>
            <div style={{ flexGrow: 1, overflow: 'auto' }}>
                <Groups groupList={groups} handleClickOnEdit={handleClickEdit} />
            </div>
            <ModalGroups
                show={showGroupDialog}
                editing={editing}
                close={() => {
                    setShowGroupDialog(false)
                    setEditing(null)
                }}
                userList={isClientUser || isWhitelistedDemoUser ? users : []}
                grName={editingSelection ? editingSelection.group_name : ''}
                group_id={editingSelection ? editingSelection.group_id : ''}
                groupMembers={editingSelection ? editingSelection.user_groups : []}
            />
        </div>
    );
}

export default GroupsTab;