import Hotjar from "@hotjar/browser"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { HttpStatusCode } from "axios"
import client, { workspaceApiClient } from "../../api/client"
import type UserModel from "../../api/models/user.model"
import UserTransformer from "../../api/transformers/user.transformer"
import TokenEnum from "../../enums/token.enum"
import { type AppDispatch, createAppAsyncThunk } from "../index"
import { setInitialised, setIsAuthenticating, setLoggedIn, setLoggedOut } from "../reducers/auth.reducer"
import { snacksErrorMessage, snacksSuccessMessage } from "./snacks.action"

export const identifyUser = (user: UserModel): void => {
    Hotjar.identify(user.userId.toString(), {
        first_name: user.nameFirst,
        last_name: user.nameLast,
        email: user.email,
    })
}

export const configureInterceptorsAction = createAsyncThunk<Promise<void>, { accessToken: string, expiry: number }>(
    "auth/interceptors",
    async ({ accessToken, expiry }, { dispatch }) => {
        client.interceptors.request.use((config) => {
            if (Date.now() >= +expiry) {
                dispatch(logoutAction)
                return config
            }

            config.headers.Authorization = `Bearer ${accessToken}`
            return config
        })

        workspaceApiClient.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${accessToken}`
            return config
        })
    })

export const initialiseAppAction = createAsyncThunk<Promise<void>>(
    "auth/init",
    async (_, { dispatch }) => {
        const accessToken = localStorage.getItem(TokenEnum.ACCESS_TOKEN)
        const expiry = localStorage.getItem(TokenEnum.EXPIRY)
        if (accessToken === null || expiry === null) {
            dispatch(setInitialised())
            return
        }

        if (Date.now() >= +expiry) {
            dispatch(logoutAction)
            dispatch(setInitialised())
            return
        }

        try {
            const Authorization = `Bearer ${accessToken}`
            const res = await client.get("/user", {
                headers: { Authorization },
            })

            void dispatch(configureInterceptorsAction({ accessToken, expiry: +expiry }))

            const user: UserModel = UserTransformer(res.data)
            identifyUser(user)
            dispatch(setLoggedIn(user))
            dispatch(setInitialised(user))
        } catch (e: any) {
            if (e.code === HttpStatusCode.Unauthorized) {
                dispatch(logoutAction)
                dispatch(setInitialised())
                void dispatch(snacksSuccessMessage("Your session has expired, please login again"))
            }
        }
    })

export const loginAction = createAppAsyncThunk<Promise<void>, { username: string, password: string }>(
    "auth/login",
    async (payload, { dispatch }) => {
        try {
            dispatch(setIsAuthenticating(true))
            const res = await client.post("/login", payload)
            const user: UserModel = UserTransformer(res.data)

            const { token: accessToken, expires_in: expiresIn } = res.data
            identifyUser(user)

            const expiry = Date.now() + expiresIn
            localStorage.setItem(TokenEnum.ACCESS_TOKEN, accessToken)
            localStorage.setItem(TokenEnum.EXPIRY, expiry)

            void dispatch(configureInterceptorsAction({ accessToken, expiry }))
            dispatch(setLoggedIn(user))
            void dispatch(snacksSuccessMessage("Logged in successfully"))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            dispatch(setIsAuthenticating(false))
        }
    })

export const logoutAction = createAsyncThunk(
    "auth/logout",
    async (_, { dispatch }) => {
        localStorage.removeItem(TokenEnum.ACCESS_TOKEN)
        localStorage.removeItem(TokenEnum.EXPIRY)
        dispatch(setLoggedOut())
        void dispatch(snacksSuccessMessage("Logged out"))
    })

export const registerAction = createAsyncThunk<Promise<void>, RegisterPayload, { dispatch: AppDispatch }>(
    "auth/register",
    async (payload, { dispatch }) => {
        try {
            dispatch(setIsAuthenticating(true))
            await client.post("/user", payload)
            void dispatch(snacksSuccessMessage(`User ${payload.email} created`))
            void dispatch(loginAction({
                username: payload.email,
                password: payload.password,
            }))
        } catch (e: any) {
            void dispatch(snacksErrorMessage(e.response?.data?.message ?? e.toString()))
        } finally {
            dispatch(setIsAuthenticating(false))
        }
    }
)

export interface RegisterPayload {
    name_first: string
    name_last: string
    email: string
    password: string
    country_code: string | null
    mobile: string | null
}
