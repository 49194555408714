/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Permission } from '../../components/Permission';
import translate from '../../utils/translate';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInvitationsRequest, revokeInvitationRequest, updateInvitationRequest } from '../../../store/old/Invitation/Invitation.action';
import './UsersManagement.scss'
import AukButton from '../../components/AukButton';
import AukTooltip from '../../components/AukTooltip';
import { Table } from 'antd';
import { arrayInvitations } from '../../../store/old/Invitation/Invitation.selector';
import ModalUserInvite from './ModalUserInvite';

const InvitesTab = (props) => {
    const dispatch = useDispatch();
    const invitations = useSelector(arrayInvitations);

    const [showInviteDialog, setShowInviteDialog] = useState(false)

    useEffect(() => {
        dispatch(getAllInvitationsRequest())
    }, [])

    
    const accept = (row) => {
        dispatch(updateInvitationRequest({ invite_id: row.invite_id, accept: true }))
    }

    const reject = (row) => {
        dispatch(updateInvitationRequest({ invite_id: row.invite_id, accept: false }))
    }

    const revoke = (row) => {
        dispatch(revokeInvitationRequest({ single: row }))
    }

    const columns = [
        {
            title: translate('email'),
            render: (text, record, index) => {
                const { type } = invitations[index];
                return type === 'request'
                    ? invitations[index].requester.email
                    : invitations[index].recipient;
            },
        },
        {
            title: translate('status'),
            render: (text, record, index) => {
                const { type, status } = invitations[index];
                let message =
          type === 'request' ? 'New user request' : 'User invite sent';
                status === 'expired' && (message = 'Invitation expired');
                return <div className="users-cursive">{message}</div>;
            },
        },
        {
            render: (text, record, index) => {
                const { type, status } = invitations[index];
                let actions = null;
                type === 'request' &&
          (actions = (
              <Permission forResource resource="users" canDo="edit">
                  <div className="d-flex flex-row">
                      <AukButton.Green
                          style={{ width: 80 }}
                          onClick={() =>
                              accept(invitations[index], index)
                          }
                      >
                          {translate('accept')}
                      </AukButton.Green>
                      <AukButton.Red
                          className="ml-1"
                          style={{ width: 80 }}
                          onClick={() =>
                              reject(invitations[index], index)
                          }
                      >
                          {translate('reject')}
                      </AukButton.Red>
                  </div>
              </Permission>
          ));
                type === 'invite' &&
          (actions = (
              <Permission forResource resource="users" canDo="full">
                  <div>
                      {/* <button
              className="btn b-accept acc role-btn"
              onClick={() => 
                props.resend(invitations[row.index], row.index)
              }>
              RESEND
            </button> */}
                      <AukButton.Red
                          style={{ width: 80 }}
                          onClick={() =>
                              revoke(invitations[index], index)
                          }
                      >
                          {translate('revoke')}
                      </AukButton.Red>
                  </div>
              </Permission>
          ));
                return actions;
            },
        },
    ];

    return (
        <div className="invites-tab-content">
            <Permission forResource resource="users" canDo="edit">
                <div className="d-flex w-100 justify-content-end">
                    <AukTooltip.Help title={"Invite user(s)"}>
                        <AukButton.Outlined
                            onClick={() => setShowInviteDialog(true)}
                            className="auk-button auk-button--round"
                        >
                            <i className="fas fa-plus" />
                        </AukButton.Outlined>
                    </AukTooltip.Help>
                </div>
            </Permission>
            <Table
                style={{ marginTop: '0.5em' }}
                pagination={false}
                columns={columns}
                dataSource={invitations}
                rowKey="invitation_id"
            />
            <ModalUserInvite
                show={showInviteDialog}
                close={() => setShowInviteDialog(false)}
            />
        </div>
    );
};

export default InvitesTab;
