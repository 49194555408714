/* eslint-disable react/prop-types */
import React from 'react';
import update from 'react-addons-update';
import { Modal } from 'reactstrap';
import Tag from '../../components/Tag';
import { flash } from '../../components/Flash';
import translate from '../../utils/translate';
import { connect } from 'react-redux';
import { createInvitationRequest } from '../../../store/old/Invitation/Invitation.action';

class ModalUserInvite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emails: [],
            emailValid: false,
            formErrors: { email: '' },
            formValid: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.sendInvites = this.sendInvites.bind(this);
        this.handleAddEmail = this.handleAddEmail.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    handleChange(e) {
        const value = e.target.value;
        this.setState({ email: e.target.value }, () => {
            this.validateEmail(value);
        });
    }

    handleKeyPress(e) {
        if (e.charCode === 13) {
            this.handleAddEmail();
        }
    }

    handleAddEmail() {
        if (this.state.emailValid) {
            this.setState({
                email: '',
                emails: this.state.emails.concat(this.state.email),
            });
        } else {
            flash({
                message: 'Please key in a valid email address',
                status: 'error',
            });
        }
    }

    removeTag(idx) {
        this.setState({
            emails: update(this.state.emails, {
                $splice: [[idx, 1]],
            }),
        });
    }

    validateEmail(value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        value = value.trim();
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';

        this.setState(
            {
                formErrors: fieldValidationErrors,
                emailValid: emailValid,
            },
            this.validateForm
        );
    }

    validateForm() {
        this.setState({ formValid: this.state.emailValid });
    }

    sendInvites() {
        let { emails, email } = this.state;
        email = email.trim();
        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        emailValid && (emails = emails.concat(email));
        emails = emails.map((e) => e.trim());

        if (emails.length) {
            this.props.createInvitation(emails, () => {
                this.props.close();
            });
        }
        // emails.length && this.handleSubmit(emails);
        this.resetFields();
    }

    resetFields() {
        this.setState({
            email: '',
            emails: [],
            emailValid: false,
            formErrors: { email: '' },
            formValid: false,
        });
    }

    toggle() {
        this.props.close();
        this.resetFields();
    }

    render() {
        const { formValid, emailValid, email, emails } = this.state;
        const { show } = this.props;
        const { toggle } = this;

        let inputClassed =
      emailValid === false
          ? 'form-control'
          : emailValid === null
              ? 'form-control is-invalid'
              : 'form-control is-valid';

        const valid = (
            <input
                type="email"
                className={inputClassed}
                placeholder="Email"
                value={email}
                onChange={(event) => this.handleChange(event)}
                onKeyPress={this.handleKeyPress}
            />
        );

        return (
            <Modal isOpen={show} toggle={toggle} backdrop="static">
                <div className="panel">
                    <div className="panel-header">
                        <p className="panel-title">
                            Invite new user(s)
                        </p>
                    </div>
                    <div className="panel-body">
                        <div className="row pad-top-30 pad-btm-10">
                            <div className="col-3 d-flex justify-content-end align-items-center">
                                <label className="check text-align-right">
                                    {translate('email')}
                                </label>
                            </div>
                            <div className="col-8">{valid}</div>
                            <div className="col-1 pl-0 d-flex justify-content-center align-items-center vcenter">
                                <div className="btn-add-users" onClick={this.handleAddEmail} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 d-flex justify-content-end align-items-center">
                                <label className="check text-align-right">
                                    {translate('invitees')}
                                </label>
                            </div>
                            <div className="col-9 align-self-flex-end flex-wrap invitees">
                                {emails.map((s, i) => (
                                    <Tag handleRemove={() => this.removeTag(i)} key={i}>
                                        {s}
                                    </Tag>
                                ))}
                            </div>
                        </div>
                        <div className="text-align-right pad-top-30">
                            <p className="font-italic font-small">
                                {translate('addinviteesbypressingenterorclickingonthesign')}
                            </p>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <button onClick={toggle} className="btn b-cancel">
                            {translate('cancel')}
                        </button>
                        <button
                            onClick={this.sendInvites}
                            className="btn b-save"
                            disabled={!formValid}
                        >
                            {translate('invite')}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (root) => {
    return {};
};

const mapDisplayToProps = (dispatch) => {
    return {
        createInvitation: (emails, callback) =>
            dispatch(createInvitationRequest({ emails }, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDisplayToProps
)(ModalUserInvite);

