/* eslint-disable react/prop-types */
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Layout, Menu, Switch } from 'antd';
import {
    FilterOutlined,
    InfoCircleOutlined,
    SaveOutlined,
    TableOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';

// COMPONENT
import AukButton from '../../components/AukButton';
import AukTooltip from '../../components/AukTooltip';
import CsvFileUpload from '../../components/CSVFileUpload';
import ModalStandardTime from './Modules/ModalStandardTime';
import OeeTableCell from './Modules/OeeTableCell';
import VSpreadsheet from '../../components/VSpreadsheet';
import { SPAWrapper } from '../../components/SPAWrapper';
import { NoData } from '../../components/None';
import { saveCsv } from '../../utils/dataExports';
import { arraySkus } from '../../../store/old/Sku/Sku.selector';
import VTable from '../../components/VTable';

// TYPE
import {
    createAssetSkuStdTime,
    fetchAssetsSkusStdTimes,
    updateAssetSkuStdTime,
    bulkCreateUpdateAssetSkuStdTimes,
} from '../../../store/old/Assets/Assets.action';

// SELECTOR
import {
    arrayAssetsWithoutUsingBlock,
    assetsSkusStdTimesSelector,
} from '../../../store/old/Assets/Assets.selector';

// STYLE
import './index.scss';
import { withEntity } from '../../Wrappers/HOCs/withEntity';
import { errorFlash, flash } from '../../components/Flash';
import { withSkus } from '../../Wrappers/HOCs/withSkus';
import AukFilterSelectMulti from '../../components/AukFilterSelectMulti';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

const CSV = {
    UPLOAD: 'upload',
    TEMPLATE: 'template',
};

const VIEW = {
    LIST: 'list',
    GRID: 'grid',
};

const getStdTimeKey = (sku_id, asset_id) => sku_id + '-' + asset_id;
export const stdTimeMaxLength = 7;

const AssetSkuManagement = (props) => {
    const {
        entity: { entity_id },
    } = props;
    const dispatch = useDispatch();

    const _skus = useSelector(arraySkus);
    const _assets = useSelector(arrayAssetsWithoutUsingBlock);
    const stdTimes = useSelector(assetsSkusStdTimesSelector);

    useEffect(() => {
        dispatch(fetchAssetsSkusStdTimes(entity_id))
    }, []);

    const [assetsSelection, setAssetsSelection] = useState(
        _assets.map((a) => a.asset_id)
    );
    const [skusSelection, setSkusSelection] = useState(
        _skus.map((s) => s.sku_id)
    );
    const [view, setView] = useState(VIEW.LIST);
    const [showCsvUpload, setShowCsvUpload] = useState(false);
    const [csvTemplateString, setCSVTemplateString] = useState('');

    const assets = useMemo(() => {
        const selectionIds = new Set(assetsSelection);
        return _assets.filter((a) => selectionIds.has(a.asset_id));
    }, [_assets, assetsSelection]);

    const skus = useMemo(() => {
        const selectionIds = new Set(skusSelection);
        return _skus.filter((s) => selectionIds.has(s.sku_id));
    }, [_skus, skusSelection]);

    const gridData = useMemo(() => {
        return skus.map((sku) => {
            return assets.map((asset) => {
                const key = getStdTimeKey(sku.sku_id, asset.asset_id);

                return stdTimes[key] || { asset, sku };
            });
        });
    }, [assets, skus, stdTimes]);

    const listData = useMemo(() => {
        return assets
            .map((asset) => {
                return skus.map((sku) => ({
                    asset,
                    sku,
                    stdTime: stdTimes[getStdTimeKey(sku.sku_id, asset.asset_id)],
                }));
            })
            .reduce((acc, curr) => acc.concat(curr), []);
    }, [assets, skus, stdTimes]);

    const assetsOptions = useMemo(
        () => _assets.map((a) => ({ value: a.asset_id, title: a.asset_name })),
        [_assets]
    );

    const skusOptions = useMemo(
        () => _skus.map((s) => ({ value: s.sku_id, title: s.codeName })),
        [_skus]
    );

    const generateTemplate = useCallback(() => {
        const header =
      'ID,Asset Name,SKU Name,Auto Cycle Time (y/n),Standard Time (/s)';

        const rows = _assets
            .map((asset) => {
                return _skus.map((sku) => {
                    const assetSkuStdTime =
            stdTimes[getStdTimeKey(sku.sku_id, asset.asset_id)];
                    return [
                        `${sku.sku_id}_${asset.asset_id}`,
                        `"${asset.asset_name}"`,
                        `"${sku.codeName}"`,
                        assetSkuStdTime ? (assetSkuStdTime.autoUpdate ? 'y' : 'n') : '',
                        assetSkuStdTime ? assetSkuStdTime.std_time : '',
                    ];
                });
            })
            .reduce((acc, curr) => acc.concat(curr), [])
            .map((row) => row.join(','))
            .join('\n');

        const str = header + '\n' + rows;
        setCSVTemplateString(str);
        return str;
    }, [_assets, _skus, stdTimes]);

    const downloadTemplate = useCallback(() => {
        const template = csvTemplateString ? csvTemplateString : generateTemplate();

        saveCsv(template, 'assets_skus_std_times.csv');
    }, [csvTemplateString, stdTimes]);

    const csvDropdownButton = useMemo(() => {
        const csvOptions = [
            { key: CSV.UPLOAD, label: 'Upload standard times (.csv)' },
            { key: CSV.TEMPLATE, label: 'Download template (.csv)' },
        ];

        const handleClickMenu = ({ key }) => {
            switch (key) {
            case CSV.UPLOAD:
                return setShowCsvUpload(true);
            case CSV.TEMPLATE:
                return downloadTemplate();
            default:
                return;
            }
        };

        return (
            <AukTooltip.Help title="Bulk Create">
                <AukButton.Dropdown
                    className="auk-button--round p-0 m-0 ml-2"
                    icon={<i className="fas fa-upload" />}
                    overlay={
                        <Menu onClick={handleClickMenu}>
                            {csvOptions.map((o) => (
                                <Menu.Item key={o.key}>{o.label}</Menu.Item>
                            ))}
                        </Menu>
                    }
                />
            </AukTooltip.Help>
        );
    }, [csvTemplateString, stdTimes]);

    const parseCsvUpload = useCallback(
        (rows) => {
            try {
                const getRowValues = (row, index) => {
                    if (row.length !== 5) throw { message: `File is not valid format` };
                    const [uuid, _asset, _sku, _autoUpdate, _st] = row;

                    const [sku_id, asset_id] = uuid.replace('"', '').split('_');
                    const autoUpdate = _autoUpdate === 'y';
                    const std_time = +_st.trim().replace(/\r/, '');
                    const sku_asset_id = stdTimes[getStdTimeKey(sku_id, asset_id)]
                        ? stdTimes[getStdTimeKey(sku_id, asset_id)].sku_asset_id
                        : undefined;

                    if (
                        !new Set(['y', 'n', '']).has(_autoUpdate.toLowerCase()) ||
            !(
                _st.trim().length <= stdTimeMaxLength &&
              isFinite(std_time) &&
              +std_time >= 0
            )
                    ) {
                        throw { message: `Invalid field, please check row ${index + 2}` };
                    }

                    return { asset_id, sku_id, autoUpdate, std_time, sku_asset_id };
                };

                const dataset = rows
                    .map(getRowValues)
                    .filter(
                        ({ sku_id, asset_id, autoUpdate, std_time, sku_asset_id }) => {
                            if (sku_asset_id) {
                                const record = stdTimes[getStdTimeKey(sku_id, asset_id)];
                                return (
                                    record.autoUpdate !== autoUpdate ||
                  record.std_time !== +std_time
                                );
                            } else {
                                return autoUpdate !== false || std_time;
                            }
                        }
                    )
                    .map((row) => {
                        if (row.sku_asset_id && row.std_time === '') {
                            row.std_time = 0;
                        }
                        return row;
                    });

                if (!dataset.length) {
                    flash({ message: 'Nothing to update', status: 'info' });
                    return;
                }

                dispatch(
                    bulkCreateUpdateAssetSkuStdTimes(entity_id, dataset, () => {
                        flash({ message: 'Upload complete' });
                        setShowCsvUpload(false);
                    })
                );
            } catch (e) {
                errorFlash(e);
            }
        },
        [stdTimes]
    );

    return (
        <SPAWrapper className="assets-sku">
            <div className="d-flex mb-2">
                <div className="d-flex w-100">
                    <AukFilterSelectMulti
                        className="asset-sku-filter"
                        checkAllMenuOption={true}
                        data={assetsOptions}
                        onChange={setAssetsSelection}
                        maxTagCount={0}
                        maxTagPlaceholder={null}
                        allowClear={false}
                        suffixIcon={
                            <>
                                <FilterOutlined className="mr-2" /> Select Assets
                            </>
                        }
                    />
                    <AukFilterSelectMulti
                        className="asset-sku-filter"
                        checkAllMenuOption={true}
                        data={skusOptions}
                        onChange={setSkusSelection}
                        maxTagCount={0}
                        allowClear={false}
                        suffixIcon={
                            <>
                                <FilterOutlined className="mr-2" /> Select SKUs
                            </>
                        }
                    />
                </div>
                <div className="d-flex">
                    <AukTooltip.Help title="View list">
                        <AukButton.Blue
                            ghost={view !== VIEW.LIST}
                            icon={<UnorderedListOutlined />}
                            onClick={() => setView(VIEW.LIST)}
                        />
                    </AukTooltip.Help>
                    <AukTooltip.Help title="View table">
                        <AukButton.Blue
                            className="mr-1"
                            ghost={view !== VIEW.GRID}
                            icon={<TableOutlined />}
                            onClick={() => setView(VIEW.GRID)}
                        />
                    </AukTooltip.Help>
                    {csvDropdownButton}
                </div>
            </div>
            <Layout className="d-flex w-100 mt-2" style={{ flexGrow: 1 }}>
                {assets.length && skus.length ? (
                    view === VIEW.GRID ? (
                        <AssetSkuGrid data={gridData} columns={assets} rows={skus} />
                    ) : (
                        <AssetSkuList data={listData} />
                    )
                ) : (
                    <NoData
                        style={{ flexGrow: 1 }}
                        description="Select at least one asset and one SKU to continue"
                    />
                )}
            </Layout>
            <CsvFileUpload
                visible={showCsvUpload}
                onCancel={() => setShowCsvUpload(false)}
                onSubmit={parseCsvUpload}
                rowTransform={(s) => s.replace(/(".*?")/g, '').split(',')}
            />
        </SPAWrapper>
    );
};

export default withEntity(withSkus(AssetSkuManagement));

const AssetSkuGrid = (props) => {
    const modalRef = useRef(null);

    const openModal = (
        type,
        action,
        standardTime,
        assetFromModal
    ) => {
        modalRef.current &&
      modalRef.current.changeStateModalStandardTime(
          type,
          standardTime,
          assetFromModal,
          action
      );
    };

    const renderBodyCell = useCallback(
        ({ columnIndex, key, rowIndex, style, value }) => {
            const { asset, sku } = value;
            if (!value.sku_asset_id) {
                const stPlaceholder = {
                    asset,
                    sku,
                    std_time: 0,
                    autoUpdate: false,
                };

                return (
                    <OeeTableCell
                        isEnabled={asset.speed}
                        standardTime={stPlaceholder}
                        handleOpenModalStandardTime={(type, standardTime) => {
                            openModal(type, 'create-asset-sku', standardTime);
                        }}
                    />
                );
            }

            return (
                <OeeTableCell
                    isEnabled={asset.speed}
                    standardTime={value}
                    handleOpenModalStandardTime={(type, standardTime, assetFromModal) => {
                        openModal(type, 'update-asset-sku', standardTime, assetFromModal);
                    }}
                />
            );
        },
        []
    );

    const renderHeaderCell = useCallback(
        ({ columnIndex, key, rowIndex, style, value }) => {
            return (
                <OeeTableCell
                    asset={value}
                    isHeader={true}
                    handleOpenModalStandardTime={(type, standardTime, assetFromModal) => {
                        openModal(type, 'update-asset', standardTime, assetFromModal);
                    }}
                    isEnabled={false}
                />
            );
        },
        []
    );

    const renderAxisCell = useCallback(
        ({ columnIndex, key, rowIndex, style, value }) => {
            return (
                <div
                    title={value.codeName}
                    className="axis-cell-content"
                    style={{ height: 50, width: 180 }}
                >
                    {value.codeName}
                </div>
            );
        },
        []
    );

    return (
        <>
            <div style={{ position: 'relative', flexGrow: 1 }}>
                <div
                    style={{ position: 'absolute', width: 180, height: 100 }}
                    className="d-flex flex-column"
                >
                    {['Asset', 'Standard Time', 'Speed Loss', 'Auto Cycle Time'].map(
                        (item, i) => (
                            <div key={i} style={{ textAlign: 'right' }} className="mr-2">
                                {item}
                            </div>
                        )
                    )}
                </div>
                <VSpreadsheet
                    cellWidth={100}
                    cellHeight={50}
                    overflowHeaderHeight={120}
                    headerHeight={100}
                    axisWidth={180}
                    data={props.data}
                    columns={props.columns}
                    columnAccessor={(asset) => asset}
                    columnKeyAccessor={(a) => a.asset_id}
                    rows={props.rows}
                    rowAccessor={(sku) => sku}
                    rowKeyAccessor={(sku) => sku.sku_id}
                    renderBodyCell={renderBodyCell}
                    renderHeaderCell={renderHeaderCell}
                    renderLeftSideCell={renderAxisCell}
                    showMarkingLines={false}
                    highlightOnHover={false}
                />
            </div>
            <ModalStandardTime ref={modalRef} />
        </>
    );
};

const AssetSkuList = ({ data }) => {
    const dispatch = useDispatch();
    const { entity_id } = useSelector(currentEntitySelector);

    const save = useCallback(
        (asset_id, sku_id, stdTime, _params) => {
            const params = { asset_id, sku_id, ..._params };
            dispatch(
                stdTime ? updateAssetSkuStdTime(entity_id, params) : createAssetSkuStdTime(entity_id, params)
            );
        },
        []
    );
    const columns = useMemo(
        () => [
            {
                title: 'Asset',
                render: (text, record, index) => (
                    <AukTooltip.Info title={record.asset.asset_name}>
                        <div className="text-overflow-ellipsis">
                            {record.asset.asset_name}
                        </div>
                    </AukTooltip.Info>
                ),
                sorter: (a, b) => a.asset.asset_name.localeCompare(b.asset.asset_name),
                width: 300,
            },
            {
                title: 'SKU',
                render: (text, record, index) => (
                    <AukTooltip.Info title={record.sku.codeName}>
                        <div className="text-overflow-ellipsis">{record.sku.codeName}</div>
                    </AukTooltip.Info>
                ),
                sorter: (a, b) => a.sku.codeName.localeCompare(b.sku.codeName),
                width: 300,
            },
            {
                title: 'Auto Cycle Time',
                render: (text, record, index) => (<SwitchAutoUpdate {...record} save={save} />),
                width: 180,
            },
            {
                title: (
                    <div className="d-flex">
            Standard Time (s){' '}
                        <AukTooltip.Help title="Set as 0 to remove">
                            <span className="d-flex align-items-center ml-2">
                                <InfoCircleOutlined />
                            </span>
                        </AukTooltip.Help>
                    </div>
                ),
                render: (text, record, index) => (
                    <InputStdTime {...record} save={save} />
                ),
                width: 180,
            },
        ],
        []
    );
    return (
        <div className="d-flex flex-column h-100">
            <VTable
                columns={columns}
                dataSource={data}
                rowKey={({ asset, sku }) => `${sku.sku_id}-${asset.asset_id}`}
            />
        </div>
    );
};

const SwitchAutoUpdate = ({ asset, sku, stdTime, save }) => {
    const [autoUpdate, setAutoUpdate] = useState(
        stdTime ? stdTime.autoUpdate : false
    );

    useEffect(
        () => setAutoUpdate(stdTime ? stdTime.autoUpdate : false),
        [stdTime]
    );

    return (
        <Switch
            className="asset-sku-auto-toggle"
            onChange={(e) => {
                const result = !autoUpdate;
                save(asset.asset_id, sku.sku_id, stdTime, { autoUpdate: result });
            }}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={autoUpdate}
        />
    );
};

const InputStdTime = ({ asset, sku, stdTime, save }) => {
    const [st, setST] = useState(stdTime ? stdTime.std_time : '');

    useEffect(() => {
        setST(stdTime ? stdTime.std_time : '');
    }, [stdTime]);

    const submit = useCallback(
        (e) => {
            e.preventDefault();
            if (stdTime) {
                return save(asset.asset_id, sku.sku_id, stdTime, {
                    std_time: st === '' ? 0 : +st,
                });
            }

            if (!stdTime && +st > 0) {
                return save(asset.asset_id, sku.sku_id, stdTime, {
                    std_time: +st,
                    autoUpdate: false,
                });
            }
        },
        [stdTime, st]
    );

    return (
        <form onSubmit={submit}>
            <Input
                className="asset-sku-st-input"
                maxLength={stdTimeMaxLength}
                onChange={(e) => {
                    (e.target.value === '' ||
            (isFinite(e.target.value) && +e.target.value >= 0)) &&
            setST(e.target.value);
                }}
                value={st}
                addonAfter={
                    <button className="asset-sku-st-input__submit" type="submit">
                        <SaveOutlined />
                    </button>
                }
            />
        </form>
    );
};
