/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

// HOCs
import withAsset from '../../../Wrappers/HOCs/withAsset';
import { withEntity } from '../../../Wrappers/HOCs/withEntity';

// STORE
import { eventEmitter } from '../../../auxStore';

// HELPERS
import CONSTANTS from '../../../Constants';
import { dataApiQueryParams } from '../../../utils/controls';

// ACTIONS
import {
    fetchAssetOeeData,
    fetchAssetOperatorData,
} from '../../../../store/old/Data/Data.action';
import { fetchAssetOperatorSettings } from '../../../../store/old/Assets/Assets.action';
import { fetchAssetTargets } from '../../../../store/old/Target/Target.action';
import { fetchBlockIssues } from '../../../../store/old/Blocks/Blocks.action';

// COMPONENTS
import AssetOperatorMain from './AssetOperatorMain';

import './index.scss';
import { withIssues } from '../../../Wrappers/HOCs/withIssues';
import { withSkus } from '../../../Wrappers/HOCs/withSkus';
import withStreaming from '../../../../lib/hoc/withStreaming';

class AssetOperatorSimple extends React.Component {
    constructor(props) {
        super(props);

        this.refreshEvent = eventEmitter.on(CONSTANTS.EVENTS.REFRESH, () => {
            const query = this.payload();
            this.getData(query);
        });

        const {
            entity: { entity_id },
            asset: { asset_id, block_id },
        } = props;

        props.getBlockIssues(entity_id, block_id);
        props.getSettings(entity_id, asset_id);
    }

    componentWillUnmount() {
        this.refreshEvent();
    }

    getData(payload) {
        const { asset, entity } = this.props;
        const { entity_id } = entity;
        const { asset_id } = asset;
        const { date_range } = payload;

        this.props.getOperatorData(entity_id, asset_id, payload);
        this.props.getOEEData(entity_id, asset_id, payload);
        this.props.getAssetTargets(asset_id, { date_range });
    }

    payload() {
        const { asset, controls } = this.props;

        return { ...dataApiQueryParams(controls) };
    }

    render() {
        return <AssetOperatorMain {...(this.props)} />;
    }
}

const mapStateToProps = (rootState) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOEEData: (entityId, assetId, query, callback) => dispatch(fetchAssetOeeData(entityId, assetId, query, callback)),
        getOperatorData: (entityId, assetId, query, callback) => dispatch(fetchAssetOperatorData(entityId, assetId, query, callback)),
        getSettings: (entityId, assetId, callback) => dispatch(fetchAssetOperatorSettings(entityId, assetId, callback)),
        getAssetTargets: (assetId, query, callback) => dispatch(fetchAssetTargets(assetId, query, callback)),
        getBlockIssues: (entityId, blockId, callback) => dispatch(fetchBlockIssues(entityId, blockId, callback)),
    };
};

const AssetOperator = connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetOperatorSimple);

export default withEntity(
    withIssues(withSkus(withAsset(withStreaming(AssetOperator))))
);
