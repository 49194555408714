import { configureStore, createAsyncThunk } from "@reduxjs/toolkit"
// import authReducer from "./reducers/auth.reducer"
import snacksReducer from "./reducers/snacks.reducer"
// import logger from "redux-logger"

// old
import createSagaMiddleware from "redux-saga"
import { assetsReducer } from "./old/Assets/Assets.reducer"
import { blocksReducer } from "./old/Blocks/Blocks.reducer"
import { devicesReducer } from "./old/Devices/Devices.reducer"
import { metadataReducer } from "./old/Metadata/Metadata.reducer"
import { fusionsReducer } from "./old/Fusions/Fusions.reducer"
import { tilesReducer } from "./old/Tiles/Tiles.reducer"
import { entitiesReducer } from "./old/Entity/Entity.reducer"
import { preferencesReducer } from "./old/Preference/Preference.reducer"
import { permissionsReducer } from "./old/Permission/Permission.reducer"
import { uiReducer } from "./old/UI/uiReducer"
import { skusReducer } from "./old/Sku/Sku.reducer"
import { issuesReducer } from "./old/Issues/Issues.reducer"
import { usersReducer } from "./old/User/User.reducer"
import { groupsReducer } from "./old/Group/Group.reducer"
import { labelsReducer } from "./old/Labels/Labels.reducer"
import { dataReducer } from "./old/Data/Data.reducer"
import { notificationsReducer } from "./old/Notifications/Notifications.reducer"
import { correlationsReducer } from "./old/Correlations/Correlations.reducer"
import { schedulesReducer } from "./old/Schedules/Schedules.reducer"
import { targetsReducer } from "./old/Target/Target.reducer"
import { trackedIssuesReducer } from "./old/TrackedIssues/TrackedIssues.reducer"
import { gatewaysReducer } from "./old/Gateway/Gateway.reducer"
import { invitationsReducer } from "./old/Invitation/Invitation.reducer"
import { canvassesReducer } from "./old/Canvas/Canvas.reducer"
import { widgetsReducer } from "./old/Widgets/Widget.reducer"
import { appReducer } from "./old/rootReducer"
import rootSaga from "./old/rootSaga"
import { authenticationReducer } from "./old/Authentication/Authentication.reducer"
import moment from "moment"
import { session } from "../legacy/auxStore"
import { flash } from "../legacy/components/Flash"
import goalsReducer from "./reducers/goals.reducer"
import reportsReducer from "./reducers/reports.reducer"
import userPreferencesReducer from "./reducers/preferences.reducer"

const sagaMiddleware = createSagaMiddleware()

const systemDowntimeMiddleware: any = (rootStore: any) => (next: any) => (action: any) => {
    const {
        app: { systemDowntime },
    } = rootStore.getState()

    if (!systemDowntime) return next(action)

    const now = moment()
    const downtime = moment(systemDowntime.time)

    if (now.isSameOrAfter(downtime)) {
        session.logout()
        flash({
            message: "System under maintenance. Please try again later",
            status: "info",
            duration: 0,
            key: "DOWNTIME",
        })
        return
    }

    return next(action)
}

const tokenExpiryMiddleware: any = (_rootStore: any) => (next: any) => (action: any) => {
    if (session.isExpired) {
        session.logout()
        window.location.href = "/login"
        flash({
            message: "Session expired, please log in again.",
            status: "info",
            duration: 0,
            key: "EXPIRED_SESSION",
        })
        return
    }

    return next(action)
}

export const store = configureStore({
    reducer: {
        // auth: authReducer,
        snacks: snacksReducer,
        reports: reportsReducer,
        goals: goalsReducer,
        preferences: userPreferencesReducer,

        // legacy
        auth: authenticationReducer,
        assets: assetsReducer,
        blocks: blocksReducer,
        devices: devicesReducer,
        metadata: metadataReducer,
        fusions: fusionsReducer,
        tiles: tilesReducer,
        entity: entitiesReducer,
        preference: preferencesReducer,
        permission: permissionsReducer,
        ui: uiReducer,
        sku: skusReducer,
        issues: issuesReducer,
        user: usersReducer,
        group: groupsReducer,
        label: labelsReducer,
        data: dataReducer,
        notifications: notificationsReducer,
        correlations: correlationsReducer,
        schedules: schedulesReducer,
        target: targetsReducer,
        trackedIssues: trackedIssuesReducer,
        gateway: gatewaysReducer,
        invitation: invitationsReducer,
        app: appReducer,
        canvas: canvassesReducer,
        widgets: widgetsReducer,
        snackbars: snacksReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(
            // logger,
            systemDowntimeMiddleware,
            tokenExpiryMiddleware,
            sagaMiddleware
        ),
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const createAppAsyncThunk = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
    rejectValue: string
    extra: any
}>()
