/* eslint-disable react/prop-types */
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import AssetDevices from './Asset/AssetDevices';
import AssetFusions from './Asset/AssetFusions';
import AssetForm from './AssetForm';
import AukButton from '../../components/AukButton';
import SortableCharts from './shared/SortableCharts';
import {
    updateAsset,
    updateAssetChartOrders,
} from '../../../store/old/Assets/Assets.action';
import './AssetConfiguration.scss';
import { NoAccess } from '../../components/None';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import useTranslate from '../../../hooks/useTranslate';
const { TabPane } = Tabs;

const AssetConfiguration = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(userSelector);
    const { entity_id } = useSelector(currentEntitySelector)
    const isLoading = useSelector((appState) => appState.ui.loader.count);
    const { translate } = useTranslate()
    const { asset, cancel, onUpdate, showDeleteAsset = true } = props;
    const { asset_id } = asset;

    const chartOrdersRef = useRef();

    const submitChartOrders = () => {
        const data = chartOrdersRef.current.getData();
        dispatch(updateAssetChartOrders(entity_id, asset_id, data));
    };

    const hasDevicesViewAccess = authUser.check_resource_policy(
        'devices',
        true,
        false,
        false
    );
    const hasFusionsViewAccess = authUser.check_resource_policy(
        'fusions',
        true,
        false,
        false
    );

    return (
        <Tabs
            className="asset-configure-tabs w-100 h-100"
            defaultActiveKey="1"
            size="small"
            type="card"
            destroyInactiveTabPane
        >
            <TabPane tab={translate('Asset')} key="1">
                <AssetForm
                    asset={asset}
                    submit={(data) => {
                        dispatch(updateAsset(entity_id, { asset_id, ...data }, onUpdate));
                    }}
                    cancel={cancel}
                    showDelete={showDeleteAsset}
                />
            </TabPane>
            <TabPane tab={translate("IoT Nodes")} key="2">
                {hasDevicesViewAccess ? (
                    <AssetDevices asset={asset} devices={asset.devices} />
                ) : (
                    <NoAccess className="w-100 h-100" />
                )}
            </TabPane>
            <TabPane tab={translate("Fusions")} key="3">
                {hasFusionsViewAccess ? (
                    <AssetFusions asset={asset} fusions={asset.fusions} />
                ) : (
                    <NoAccess className="w-100 h-100" />
                )}
            </TabPane>
            <TabPane tab={translate("Chart Order")} key="4">
                {hasDevicesViewAccess && hasFusionsViewAccess ? (
                    <div className="d-flex flex-column h-100 justify-content-between">
                        <SortableCharts ref={chartOrdersRef} charts={asset.charts} />
                        <div className="chart-order__submit">
                            <AukButton.Save
                                onClick={submitChartOrders}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                ) : (
                    <NoAccess className="w-100 h-100" />
                )}
            </TabPane>
        </Tabs>
    );
};

export default AssetConfiguration;
