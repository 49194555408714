/* eslint-disable react/prop-types */

import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Checkbox, Popover } from 'antd';
import { has, round } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
    ExclamationCircleOutlined,
    SaveFilled,
} from '@ant-design/icons';

// REDUX
import { issuesState } from '../../../store/old/Issues/Issues.selector';

// HELPERS
import CONSTANTS from '../../Constants';
import { flash } from '../../components/Flash';
import {
    checkFutureLabel,
    compileLabel,
    fetchAssetIssueLabels,
    generateStopLabel,
    getSpan,
} from '../../utils/labels';
import { oeeFactors, oeeWaterfall } from '../../utils/oee';

// COMPONENTS
import AukButton from '../../components/AukButton';
import {
    Labeller,
    LabellerBody,
    LabellerBodyItem,
    LabellerHeader,
    LabellerHeaderItem,
    LabelRangeZoom,
} from './components';
import { DangerZone, DangerZoneItem } from '../../components/DangerZone';

import { LabelNotesInput } from '.';
import { controlsState } from '../../../store/old/UI/Controls/Controls.selector';
import { CreateLabel, DeleteLabel, StopLabel, UpdateLabel } from '../../../store/old/Labels/Labels.action';
import { showLabelNotesSelector } from '../../../store/old/Labels/Labels.selector';
import { isQualityIssue } from '../../utils/issues';
import PermittedIssuesTreeSelect from './components/PermittedIssuesTreeSelect';
import IssueLabelListItem from './components/IssueLabelListItem';
import useLabelCollision from '../../../hooks/useLabelCollision';

// add with data delete props
export const AssetIssueLabeller = (props) => {
    const dispatch = useDispatch()
    const { asset, showDataDelete, openDataDelete, onSuccess } = props;

    const showLabelNotes = useSelector(showLabelNotesSelector)
    const { issues } = useSelector(issuesState);
    const { oee2 } = useSelector(controlsState);

    const {
        range,
        selection,
        brushedData = [],
    } = props;

    const [formData, setFormdata] = useState({ issue_id: selection ? selection.issue.issue_id : null });
    const [override, setOverride] = useState(selection ? selection.override : false);
    const [notes, setNotes] = useState(selection ? selection.notes : '');
    const [brushStart, brushEnd] = range;

    const { labelled, loading } = useLabelCollision(() => fetchAssetIssueLabels(asset.asset_id, range), selection, [range])

    useEffect(() => {
        if (selection) {
            setOverride(selection.override);
            setNotes(selection.notes);
        }
    }, [selection]);

    const key = useMemo(() => (selection ? selection.label_id : ''), [selection]);
    const selectedIssue = useMemo(() => issues[formData.issue_id], [formData]);

    const brushedOEE = useMemo(() => {
        const index = oee2 === true ? 1 : 0;
        return oeeFactors(oeeWaterfall(brushedData))[index];
    }, [brushedData, oee2]);

    const spanDuration = useMemo(() => getSpan(range), [range]);

    const brushedOutput = useMemo(() => {
        return brushedData.reduce((acc, curr) => (acc += curr.yield), 0);
    }, [brushedData]);

    const { asset_id } = asset;
    const handleSave = useCallback(() => {
        if (!formData.issue_id || !issues[formData.issue_id])
            return flash({ message: 'Please select an issue', status: 'warning' });

        const hasDefects = has(formData, 'defects');

        if (hasDefects && +formData.defects < 0) {
            return flash({
                message: 'Defects cannot be negative',
                status: 'warning',
            });
        }

        if (hasDefects && +formData.defects > brushedOutput) {
            return flash({
                message: 'Defect quantity cannot be greater than output',
                status: 'warning',
            });
        }

        if (labelled) {
            return flash({
                message: 'Cannot overwrite existing label(s)',
                status: 'warning',
            });
        }

        const values = {
            issue: formData.issue_id,
            notes: showLabelNotes ? notes : undefined,
        };

        const requestPayload = compileLabel(
            {
                values,
                from: brushStart,
                to: brushEnd,
                label_id: selection ? selection.label_id : null,
            },
            {
                override,
                defects: hasDefects
                    ? formData.defects === null
                        ? formData.defects
                        : +formData.defects
                    : undefined,
            }
        );

        if (checkFutureLabel(requestPayload))
            return flash({
                message: 'Cannot create label in future time.',
                status: 'warning',
            });

        if (selection) {
            dispatch(UpdateLabel(asset_id, requestPayload, onSuccess))
            return;
        }

        dispatch(CreateLabel(asset_id, requestPayload, onSuccess))
    }, [formData, override, range, notes, showLabelNotes, labelled]);

    const handleDelete = () => {
        if (!selection) return;
        if (!selection.isComplete) {
            return flash({
                message: 'Please stop label before deleting.',
                status: 'warning',
            });
        }
        
        dispatch(DeleteLabel(asset_id, selection, onSuccess));
    };

    return (
        <Labeller className="issue-labeller" id="issueLabeller" key={key}>
            <LabellerHeader className="flex-column">
                <LabellerHeaderItem label="Span:">{spanDuration}</LabellerHeaderItem>
                <LabellerHeaderItem label="Range">
                    <LabelRangeZoom
                        range={range}
                        disableZoom={selection ? !selection.isComplete : false}
                    />
                </LabellerHeaderItem>
            </LabellerHeader>
            <LabellerBody>
                <LabellerBodyItem
                    header="OEE Selection"
                    addonHeader={showDataDelete ? [
                        <DangerZoneItem
                            key={key}
                            title="Delete device data"
                            description="Delete highlighted data."
                            handleClick={openDataDelete}
                            action="Delete"
                            confirmText="Are you sure ? This action is irreversible."
                        />,
                    ]: undefined}
                >
                    <div>
                        {oee2 ? 'OEE-2' : 'OEE-1'}: {round(brushedOEE.value, 2)} %
                    </div>
                    {asset && asset.primary && asset.primary.mode !== '2a' ? (
                        <div>
              Output: {round(brushedOutput, 2).toLocaleString()}{' '}
                            {asset.primary && asset.primary.units}
                        </div>
                    ) : null}
                </LabellerBodyItem>
                {selection ? (
                    <LabellerBodyItem header={'Label'}>
                        <IssueLabelListItem
                            data={selection}
                            onStop={(d) => dispatch(StopLabel(asset.asset_id, generateStopLabel(d), onSuccess))}
                        />
                    </LabellerBodyItem>
                ) : null}
                        <LabellerBodyItem header={selection ? 'Edit' : 'Create'}>
                            <PermittedIssuesTreeSelect
                                forBlocks={[asset.block_id]}
                                asset={asset}
                                withDefects={shouldShowDefects(asset.primary)}
                                data={selection}
                                onChange={setFormdata}
                            />
                        </LabellerBodyItem>
                        {(selection && selection.isComplete) || !selection ? (
                            <LabellerBodyItem header="Options">
                                <Checkbox
                                    checked={override}
                                    onChange={(e) => setOverride(e.target.checked)}
                                >
                                    <div className="d-flex align-items-center">
                    OEE Override{' '}
                                        {isQualityIssue(selectedIssue) ? (
                                            <Popover
                                                placement="right"
                                                content={
                                                    <span style={{ width: 200, color: '#dc3545' }}>
                            Does not apply to QUA issues
                                                    </span>
                                                }
                                            >
                                                <ExclamationCircleOutlined
                                                    className="ml-2"
                                                    style={{ color: '#dc3545' }}
                                                />
                                            </Popover>
                                        ) : null}
                                    </div>
                                </Checkbox>
                            </LabellerBodyItem>
                        ) : null}
                        {showLabelNotes ? (
                            <LabellerBodyItem header="Notes">
                                <LabelNotesInput
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </LabellerBodyItem>
                        ) : null}
                        <AukButton.Blue
                            className="my-3"
                            icon={<SaveFilled />}
                            onClick={handleSave}
                            disabled={!!loading}
                        >
              Save
                        </AukButton.Blue>
                        {selection && (
                            <LabellerBodyItem header="Last Modified">
                                <div className="labeller__last-modified">
                                    {selection.lastModified}
                                </div>
                            </LabellerBodyItem>
                        )}
                        {selection ? (
                            <DangerZone header="Delete">
                                <DangerZoneItem
                                    title="Label"
                                    description="Delete selected label."
                                    handleClick={handleDelete}
                                    action="Delete"
                                    confirmText="Are you sure? This may affect your OEE."
                                />
                            </DangerZone>
                        ) : null}
            </LabellerBody>
        </Labeller>
    );
};

const shouldShowDefects = (primaryInput) => {
    return primaryInput &&
    (primaryInput.mode === CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT ||
    primaryInput.mode === CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE ||
    primaryInput.mode === CONSTANTS.CHANNELS.MODES.ANALOG_MAX ||
    primaryInput.mode === CONSTANTS.CHANNELS.MODES.ANALOG_MIN)
}
