import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
// import { useAppSelector } from "../store/hooks"
// import RegisterPage from "./register/RegisterPage"
// import DashboardPage from "./dashboard/DashboardPage"
// import { LayoutAuthenticated, LayoutUnauthenticated } from '../Layout';
// import ProtectedRoute from './ProtectedRoute'
// import JoinEntity from '../Pages/Account/JoinEntity';
// import SetupEntity from '../Pages/Account/SetupEntity';
// import SetupGateway from '../Pages/Account/SetupGateway';
// import LogIn from '../Pages/Account/LogIn';
// import Register from '../Pages/Account/Register';
// import ResetPassword from '../Pages/Account/ResetPassword';
// import ChangePassword from '../Pages/Account/ChangePassword';
// import VerifyEmail from '../Pages/Account/VerifyEmail';
// import Profile from '../Pages/Account/Profile';
// import TrendAnalysis from '../Pages/TrendAnalysis';
// import Notifications from '../Pages/Notifications';
// import UserGuide from '../Pages/UserGuide';
import { ROUTES } from "./routes"
// import LoginPage from "./login/LoginPage"
import { LayoutUnauthenticated, LayoutAuthenticated } from "../components/Layout"
import ProtectedRoute from "./ProtectedRoute"
import Search from "../legacy/routes/Search/Search.container"
import IssueTracker from "../legacy/routes/IssueTracker/IssueTracker.container"
import Utilization from "../legacy/routes/Utilization/Utilization.container"
import RegressionAnalysis from "../legacy/routes/RegressionAnalysis/RegressionAnalysis.container"
import Scheduling from "../legacy/routes/Entity/EntitySchedule"
import { TargetSetting } from "../legacy/routes/TargetSetting"
import AssetManagement from "../legacy/routes/Assets/AssetManagement"
import IssueManagement from "../legacy/routes/IssueManagement"
import SKUManagement from "../legacy/routes/SKUManagement/SKUMangement.container"
import StandardTimes from "../legacy/routes/SkuAssetStandardTime"
import HardwareManagement from "../legacy/routes/Devices/HardwareManagement"
import EntityManagement from "../legacy/routes/Entity"
import TrendAnalysis from "../legacy/routes/TrendAnalysis/TrendAnalysis.container"
import UserGuide from "../legacy/routes/UserGuide/UserGuide"
import SignIn from "../legacy/routes/Account/SignIn"
import SignUp from "../legacy/routes/Account/SignUp"
import Dashboard from "../legacy/routes/Dashboard"
import PasswordResetPanel from "../legacy/routes/Account/PasswordResetPanel"
import ChangePassword from "../legacy/routes/Account/ChangePassword"
import EmailVerification from "../legacy/routes/Account/EmailVerification"
import NotificationsContainer from "../legacy/routes/Notifications/Notifications.container"
import { session } from "../legacy/auxStore"
import Asset from "../legacy/routes/Assets/Asset"
import AssetOperator from "../legacy/routes/Assets/AssetOperator"
import AssetPlanner from "../legacy/routes/Assets/AssetPlanner"
import ParetoAnalysis from "../legacy/routes/ParetoAnalysis"
import ShopFloor from "../legacy/routes/ShopFloor"
import Permissions from "../legacy/routes/Permissions"
import JoinCompany from "../legacy/routes/Account/JoinCompany"
import SetupGateway from "../legacy/routes/Gateway/SetupGateway"
import UserConfig from "../legacy/routes/Account/UserConfig"
import ImpactPage from "./impact/ImpactPage"
import UsersPage from "./users/UsersPage"
import ObjectivesPage from "./goals/ObjectivesPage/ObjectivesPage"
import ObjectivePage from "./goals/ObjectivePage/ObjectivePage"
import BlockPage from "./block/BlockPage"

const AppRouter: React.FC<unknown> = () => {
    // const user = useAppSelector(({ auth }) => auth)
    // const { user } = auth
    return <Routes>
        <Route
            element={
                <ProtectedRoute
                    redirectPath={ROUTES.LOGIN}
                    // isAllowed={user?.userId !== null}
                    isAllowed={!!session.user_id}
                />
            }
        >
            <Route
                path="/"
                element={<Navigate to={ROUTES.DASHBOARD}/>}
            />
            <Route element={<LayoutUnauthenticated/>}>
                <Route path={ROUTES.JOIN_ENTITY} element={<JoinCompany />} />
            </Route>
            <Route
                element={
                    <ProtectedRoute
                        redirectPath={ROUTES.JOIN_ENTITY}
                        // isAllowed={user?.entityId !== null}
                        isAllowed={session.entity_id !== "null"}
                    />
                }
            >
                <Route element={<LayoutAuthenticated/>}>
                    {/* <Route path={ROUTES.SETUP_ENTITY} element={<SetupEntity />} /> */}
                    <Route path={ROUTES.SETUP_GATEWAY} element={<SetupGateway />} />
                    <Route path={ROUTES.USER} element={<UserConfig />} />

                    <Route path={ROUTES.DASHBOARD} element={<Dashboard/>}/>
                    <Route path={`${ROUTES.DASHBOARD}/${ROUTES.PRODUCTION_ACHIEVEMENT}`} element={<ShopFloor/>}/>
                    <Route path={`${ROUTES.BLOCK}/:blockId`} element={<BlockPage/>} />
                    <Route path={`${ROUTES.ASSET}`}>
                        <Route path={`:blockId${ROUTES.OPERATOR}`} element={<AssetOperator/>}/>
                        <Route path={`:blockId${ROUTES.PLANNER}`} element={<AssetPlanner/>}/>
                        <Route path={":blockId"} element={<Asset/>}>
                        </Route>
                    </Route>
                    <Route path={`${ROUTES.PARETO}/:blockId`} element={<ParetoAnalysis/>} />
                    <Route path={ROUTES.IMPACT} element={<ImpactPage />} />
                    <Route path={ROUTES.GOALS} element={<ObjectivesPage />} />
                    <Route path={`${ROUTES.GOALS}/:objectiveId`} element={<ObjectivePage />} />
                    <Route path={ROUTES.SEARCH} element={<Search />} />
                    <Route path={ROUTES.ISSUE_TRACKER} element={<IssueTracker />} />
                    <Route path={ROUTES.NOTIFICATIONS} element={<NotificationsContainer />} />
                    <Route path={ROUTES.UTILIZATION} element={<Utilization />} />
                    <Route path={ROUTES.TREND} element={<TrendAnalysis />} />
                    <Route path={ROUTES.REGRESSION} element={<RegressionAnalysis />} />
                    <Route path={ROUTES.SCHEDULING} element={<Scheduling />} />
                    <Route path={ROUTES.TARGET_SETTING} element={<TargetSetting />} />
                    <Route path={ROUTES.ASSET_MANAGEMENT} element={<AssetManagement />} />
                    <Route path={ROUTES.ISSUE_MANAGEMENT} element={<IssueManagement />} />
                    <Route path={ROUTES.SKU_MANAGEMENT} element={<SKUManagement />} />
                    <Route path={ROUTES.STANDARD_TIMES} element={<StandardTimes />} />
                    <Route path={ROUTES.HARDWARE_MANAGEMENT} element={<HardwareManagement />}/>
                    <Route path={ROUTES.PREFERENCES} element={<EntityManagement />} />
                    <Route path={ROUTES.PERMISSIONS_MANAGEMENT} element={<Permissions />} />
                    <Route path={ROUTES.USER_MANAGEMENT} element={<UsersPage />} />
                    <Route path={ROUTES.USER_GUIDE} element={<UserGuide />} />
                </Route>
            </Route>
        </Route>
        <Route element={<LayoutUnauthenticated/>}>
            <Route
                element={
                    <ProtectedRoute
                        redirectPath={ROUTES.DASHBOARD}
                        isAllowed={!session.isAuthenticated}
                    />
                }
            >
                <Route path={ROUTES.LOGIN} element={<SignIn/>}/>
                <Route path={ROUTES.REGISTER} element={<SignUp/>}/>
                <Route path={ROUTES.RESET_PASSWORD} element={<PasswordResetPanel/>}/>
                <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword/>}/>
                <Route path={ROUTES.CHANGE_PASSWORD} element={<EmailVerification/>}/>
                <Route path={ROUTES.VERIFY_EMAIL} element={<EmailVerification/>}/>
            </Route>
            {/* <Route path="*" element={404}/> */}
        </Route>
        <Route path="*" element={404}/>
    </Routes>
}

export default AppRouter
