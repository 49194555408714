/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button } from 'antd';
import { filter } from 'lodash';
import { random } from 'faker';
import { connect } from 'react-redux';
import SimpleTextModal from '../../../components/Modal/SimpleTextModal/SimpleTextModal';
import AlertListener from './components/AlertListener';
import BlockTitle from '../shared/BlockTitle';
import BarcodeScanner from '../../../components/BarcodeScanner';
import CustomModal from '../../../components/Modal';
import LabelCounters from '../shared/LabelCounters';
import AukTooltip from '../../../components/AukTooltip';
import OperatorChart from './components/OperatorChart';
import OperatorPreferencesModal from './components/OperatorPreferencesModal';
import { Panel, PanelWO } from './components/OperatorLabelPanel';
import { flash } from '../../../components/Flash';
import { Permission } from '../../../components/Permission';
import CONSTANTS from '../../../Constants';
import { LabelConstants as K } from '../../../../store/old/Labels/Labels.constants';
import { generateForwardLabel, generateStopLabel } from '../../../utils/labels';
import { permittedIssuesByBlock } from '../../../utils/issues';
import { getWindow } from '../../../../store/old/UI/Controls/Controls.selector';
import { NoData } from '../../../components/None';
import { getBlocksTreeData } from '../../../utils/blocks';
import withRouter from '../../../Wrappers/HOCs/withRouter';
import translate from '../../../utils/translate';
import { CreateLabel, StopLabel, UpdateLabel } from '../../../../store/old/Labels/Labels.action';
import { showLabelNotesSelector } from '../../../../store/old/Labels/Labels.selector';

class AssetOperatorMain extends Component {
    constructor(props) {
        super(props);
        this.PanelID = {
            OEE: K.TYPES['ISSUE'],
            SKU: K.TYPES['SKU'],
            WO: K.TYPES['W/O'],
        };

        this.state = {
            showConfig: false,
            wo: [],
            isNotesSaving: false,
            isNotesModalOpen: false,
            notesValue: undefined,
            onNotesSave: undefined,
            modal: {
                show: false,
                title: '',
                body: '',
                onProceed: () => {},
            },
            barcodeScanner: {
                show: false,
                handleScan: '',
            },
        };

        this.clickToAssetView = this.clickToAssetView.bind(this);

        this.addWorkOrder = this.addWorkOrder.bind(this);

        this.handleClickItemIssue = this.handleClickItemIssue.bind(this);
        this.handleClickItemProduction = this.handleClickItemProduction.bind(this);

        this.handleScanBarcode = this.handleScanBarcode.bind(this);
        this.resetBarcodeScanner = this.resetBarcodeScanner.bind(this);

        this.resetModal = this.resetModal.bind(this);
        this.toggleConfigPanel = this.toggleConfigPanel.bind(this);
        this.handleNotesModalClose = this.handleNotesModalClose.bind(this);
    }

    toggleConfigPanel() {
        this.setState({ showConfiguration: !this.state.showConfiguration });
    }

    panelData(panelID) {
        const { OEE, SKU, WO } = this.PanelID;
        const {
            whitelist,
            skus,
            issues,
        } = this.props;

        if (panelID === SKU) {
            return whitelist.skus
                .filter((sku_id) => skus[sku_id])
                .map((sku_id) => this.getSKUPanelItem(skus[sku_id]));
        }

        if (panelID === OEE) {
            return this.allowedIssues
                .sort(
                    (a, b) =>
                        issues[a].oeeCategory.issue_id - issues[b].oeeCategory.issue_id
                )
                .map((issue_id) => this.getIssuePanelItem(issues[issue_id]));
        }

        if (panelID === WO) {
            return this.getWOPanelItem();
        }

        return [];
    }

    getSKUPanelItem(sku) {
        const { liveProductLabel } = this.props.asset;

        const isDisabled =
      liveProductLabel &&
      liveProductLabel.sku &&
      liveProductLabel.sku.sku_id !== sku.sku_id;

        return {
            data: sku,
            label: `[${sku.code}] ${sku.name}`,
            collate: (d) => ({ sku: d }),
            isDisabled,
        };
    }

    getIssuePanelItem(issue) {
        const { liveIssueLabel } = this.props.asset;
        const isDisabled =
      liveIssueLabel &&
      liveIssueLabel.issue &&
      liveIssueLabel.issue.issue_id !== issue.issue_id;

        const style = {
            borderLeft: `10px solid ${issue.color}`,
        };

        return {
            data: issue,
            label: issue.name,
            collate: (d) => ({ issue: d }),
            isDisabled,
            style,
        };
    }

    getWOPanelItem() {
        const { liveProductLabel } = this.props.asset;
        if (!liveProductLabel) return [];

        const key = K.TYPES['W/O'];
        const value = liveProductLabel.getValue(key);

        if (!value) return [];

        const data = { [key]: value };

        return [
            {
                data,
                label: value,
                collate: (d) => d,
            },
        ];
    }

    getPanel(id) {
        const { OEE, SKU, WO } = this.PanelID;

        switch (id) {
        case OEE:
            return (
                <Panel
                    title="OEE"
                    data={this.panelData(OEE)}
                    handleClickItem={this.handleClickItemIssue}
                    handleClickQR={() => this.handleClickQR(OEE)}
                />
            );

        case SKU:
            return (
                <Panel
                    title="SKU"
                    data={this.panelData(SKU)}
                    handleClickItem={this.handleClickItemProduction}
                    handleClickQR={() => this.handleClickQR(SKU)}
                />
            );

        case WO:
            return (
                <PanelWO
                    title="W/O"
                    data={this.panelData(WO)}
                    handleClickAdd={this.addWorkOrder}
                    handleClickItem={this.handleClickItemProduction}
                    handleClickQR={() => this.handleClickQR(WO)}
                />
            );

        default:
            return null;
        }
    }

    handleClickItemProduction(d) {
        const { asset } = this.props;

        // if (!authUser.check_resource_policy('assets', false, true, true)) return;

        const { liveProductLabel } = asset;

        if (!liveProductLabel) return this.startLabelDB(d);

        const isTagging = liveProductLabel.label_values.find(({ type }) =>
            Object.keys(d).includes(type)
        );

        if (isTagging) return this.stopLabelDB(liveProductLabel);

        this.setState({
            modal: {
                show: true,
                title: 'Update label',
                body: 'Add to current label?',
                onProceed: () => {
                    this.updateLabelDB(d, liveProductLabel);
                    this.resetModal();
                },
            },
        });
    }

    handleScanBarcode(type, code) {
        const { OEE, SKU, WO } = this.PanelID;

        let data;

        switch (type) {
        case OEE:
            data = this.getIssueBarcodeData(code);
            data ? this.handleClickItemIssue(data) : this.flashCodeNotFound();
            return;
        case SKU:
            data = this.getSKUBarcodeData(code);
            data ? this.handleClickItemProduction(data) : this.flashCodeNotFound();
            return;
        case WO:
            data = this.getWOBarcodeData(code);
            this.handleClickItemProduction(data);
            return;
        default:
            return;
        }
    }

    getIssueBarcodeData(str) {
        const { issues: issuesState } = this.props;

        const issue = this.allowedIssues
            .map((id) => issuesState[id])
            .find(({ name }) => name.toLowerCase() === str.toLowerCase());

        return issue && { issue };
    }

    getSKUBarcodeData(str) {
        const { whitelist, skus: skusState } = this.props;

        const sku = whitelist.skus
            .map((id) => skusState[id])
            .find(({ code }) => code === str);

        return sku && { sku };
    }

    getWOBarcodeData(str) {
        return { 'w/o': str };
    }

    handleClickQR(type) {
    // const { authUser } = this.props;
    // if (!authUser.check_resource_policy('assets', false, true, true)) return;
        this.setState({
            barcodeScanner: {
                show: true,
                handleScan: this.handleScanBarcode.bind(this, type),
            },
        });
    }

    resetBarcodeScanner() {
        this.setState({
            barcodeScanner: {
                show: false,
                handleScan: '',
            },
        });
    }

    handleClickItemIssue(d) {
        const { asset } = this.props;
        const { liveIssueLabel } = asset;

        // if (!authUser.check_resource_policy('assets', false, true, true)) return;

        if (!liveIssueLabel) {
            this.startLabelDB(d);
            return;
        }

        this.stopLabelDB(liveIssueLabel);
    }

    addWorkOrder(value) {
        this.handleClickItemProduction({ 'w/o': value });
    }

    handleUIGeneration() {
        const { OEE, SKU, WO } = this.PanelID;
        const { panels } = this.props.preferences;

        const panelsStatus = [
            { id: OEE, status: panels.showOEE },
            { id: SKU, status: panels.showSKU },
            { id: WO, status: panels.showWO },
        ];

        const isShow = filter(panelsStatus, (v) => v.status);

        return isShow.map((v) => (
            <div key={random.uuid()} className="col panel-wrapper">
                {this.getPanel(v.id)}
            </div>
        ));
    }

    handleNotesModalOpen(label) {
        const notesObj = label.label_values.find(({ type }) => type === 'notes');

        this.setState({
            isNotesModalOpen: true,
            notesValue: notesObj ? notesObj.value : undefined,
            onNotesSave: (notes) => {
                this.setState({ isNotesSaving: true });
                const { asset_id } = this.props.asset;
                const _notes = { type: 'notes', value: notes };
                const label_values = notesObj
                    ? label.label_values
                        .filter((item) => item !== notesObj)
                        .concat(_notes)
                    : [...label.label_values, _notes];
                this.props.updateLabel(
                    asset_id,
                    {
                        ...label.clone(),
                        label_values,
                    },
                    (label) => {
                        this.setState({ isNotesSaving: false });
                        if (label) {
                            flash({ message: 'Notes saved.', status: 'success' });
                            this.handleNotesModalClose();
                        }
                    }
                );
            },
        });
    }

    handleNotesModalClose() {
        this.setState({
            isNotesModalOpen: false,
            isNotesSaving: false,
            onNotesSave: undefined,
        });
    }

    resetModal() {
        this.setState({
            modal: {
                show: false,
                title: '',
                body: '',
                onProceed: () => {},
            },
        });
    }

    startLabelDB(d) {
        const {
            asset: { asset_id },
            showLabelNotes,
        } = this.props;

        const data = d;

        if (d.issue) {
            data.issue = d.issue.issue_id;
        } else if (d.sku) {
            data.sku = d.sku.sku_id;
        }

        this.props.createLabel(asset_id, generateForwardLabel(data), (label) => {
            showLabelNotes && this.handleNotesModalOpen(label);
            flash({ message: 'Commence tagging', status: 'success' });
        });
    }

    updateLabelDB(d, current) {
        const { asset_id } = this.props.asset;
        const data = d;
        if (d.sku) {
            data.sku = d.sku.sku_id;
        }

        this.props.updateLabel(asset_id, generateForwardLabel(data, current));
    }

    stopLabelDB(current) {
        const {
            asset: { asset_id },
            stopLabel,
            showLabelNotes,
        } = this.props;

        stopLabel(asset_id, generateStopLabel(current), (label) => {
            const spanExceeded = label.duration > 86400;
            showLabelNotes && !spanExceeded && this.handleNotesModalOpen(label);
            flash({ message: 'Stopped', status: 'success' });
        });
    }

    flashCodeNotFound() {
        flash({ message: 'Not found', status: 'error' });
    }

    clickToAssetView() {
        const { asset } = this.props;
        const { ASSET } = CONSTANTS.URLS;
        const { search } = window.location;

        this.props.router.navigate(`${ASSET}/${asset.block_id}${search}`);
    }

    get allowedIssues() {
        const {
            issues,
            blocks: { blocks_issues_exclusions },
            asset: { block_id },
        } = this.props;

        return permittedIssuesByBlock(
            issues,
            block_id,
            blocks_issues_exclusions
        ).filter(
            (issue_id) => issues[issue_id] && issues[issue_id].hierarchy_level > 1
        );
    }

    get chart() {
        const { preferences, asset } = this.props;

        return (
            preferences.panels.showChart &&
      asset.primary && (
                <OperatorChart
                    dateRange={this.props.dateRange}
                    meta={asset.primary}
                    asset={asset}
                    controls={this.props.controls}
                />
            )
        );
    }

    render() {
        const { asset } = this.props;

        return (
            <div className="asset--operator">
                <BlockTitle
                    id={asset.block.block_id}
                    getTreeData={(node) =>
                        getBlocksTreeData(node, {
                            disabledAccessor: (n) => !n.asset || n.isRestricted,
                        })
                    }
                >
                    {this.props.asset.charts.length ? (
                        <Permission resource="assets" forResource canDo="edit">
                            <AukTooltip.Help title={translate('configureoperatorconsole')}>
                                <Button
                                    className="auk-button auk-button--round ml-2"
                                    onClick={this.toggleConfigPanel}
                                >
                                    <i className="far fa-window-restore" />
                                </Button>
                            </AukTooltip.Help>
                        </Permission>
                    ) : null}
                    <AukTooltip.Help title={translate('defaultview')}>
                        <Button
                            className="auk-button auk-button--round ml-2"
                            onClick={this.clickToAssetView}
                        >
                            <i className="fas fa-desktop" />
                        </Button>
                    </AukTooltip.Help>
                </BlockTitle>
                {asset.charts.length ? (
                    <>
                        <LabelCounters asset={asset} />
                        <div className="basic-view-content">
                            <div
                                id="basic-view-panel-wrapper"
                                className="basic-view-panel-wrapper row"
                            >
                                {this.handleUIGeneration()}
                            </div>
                            <div className="basic-view-chart">{this.chart}</div>
                        </div>
                        <AlertListener
                            asset={asset}
                            data={asset.oee}
                            labels={asset.issueLabels}
                        />
                        <CustomModal
                            show={this.state.modal.show}
                            toggle={this.resetModal}
                            title={this.state.modal.title}
                            body={this.state.modal.body}
                            onProceed={this.state.modal.onProceed}
                        />
                        <SimpleTextModal
                            title="Notes"
                            maxLength={255}
                            value={this.state.notesValue}
                            dismissMs={10000}
                            isOpen={this.state.isNotesModalOpen}
                            isLoading={this.state.isNotesSaving}
                            onClose={() => this.handleNotesModalClose()}
                            onSave={this.state.onNotesSave}
                        ></SimpleTextModal>
                        <OperatorPreferencesModal
                            show={this.state.showConfiguration}
                            toggle={this.toggleConfigPanel}
                            asset={asset}
                            dateRange={this.props.dateRange}
                            controls={this.props.controls}
                        />
                        {this.state.barcodeScanner.show && (
                            <BarcodeScanner
                                cancel={this.resetBarcodeScanner}
                                handleScan={this.state.barcodeScanner.handleScan}
                            />
                        )}
                    </>
                ) : (
                    <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                        <NoData />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        authUser: rootState.auth.user,
        skus: rootState.sku.skus,
        preferences: rootState.preference.asset,
        whitelist: rootState.assets.operator,
        dateRange: getWindow(rootState),
        oee: rootState.assets.oee,
        blocks: rootState.blocks,
        showLabelNotes: showLabelNotesSelector(rootState)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createLabel: (asset_id, label, cb) => dispatch(CreateLabel(asset_id, label, cb)),
        updateLabel : (asset_id, label, cb) => dispatch(UpdateLabel(asset_id, label, cb)),
        stopLabel : (asset_id, label, cb) => dispatch(StopLabel(asset_id, label, cb))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetOperatorMain));
